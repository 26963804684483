
@mixin xs {
  @media (max-width: 320px) { @content; }
}
@mixin sm {
  @media (max-width: 767px) { @content; }
}
@mixin md {
  @media (min-width: 768px) { @content; }
}
@mixin lg {
  @media (min-width: 992px) { @content; }
}
@mixin xl {
  @media (min-width: 1200px) { @content; }
}
@mixin xxl {
  @media (min-width: 1920px) { @content; }
}



// FONT SIZES
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin font($size, $lh) {
	font-size: $size;
	line-height: $lh;
}

// CLEARFIX
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// INPUT
@mixin textInput($width) {
  width: $width;
  background: #fff;
  border-radius: 7px;
  border:none;
  padding:0 18px;
  color: #888888;
  font-style: italic;
  @include font-size(18px);
  font-weight: 300;
  height: 46px;
  line-height: 46px;
  box-shadow:0px -4px 0px 0px #ebe6f1;
}

// BUTTON
@mixin button($width,$height,$shadowDepth,$bgColor,$shadowColor,$textColor){
	width:$width;
	height:$height;
	line-height:$height;
	text-align:center;
	border-radius:7px;
	background:$bgColor;
	color:$textColor;
	box-shadow:0px $shadowDepth 0px 0px $shadowColor;
	border:none;
	cursor:pointer;
	transition:.1s ease-in-out;
	
	&:hover {
		opacity:.9;		
	}

	&:active {
		box-shadow:0px 1px 0px 0px $shadowColor;
		margin-top: 25px;
		margin-bottom: 15px;
	}
}

@mixin arrow_link {
    position: relative;
	overflow: visible;
    &::after {
		background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33337 8H12.6667' stroke='%230065F2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.33337L12.6667 8.00004L8 12.6667' stroke='%230065F2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		background-position: center;
		background-repeat: no-repeat;
		background-size: 80%;
		content: "";
		position: absolute;
		right: -1.2rem;
		transition: background-position .2s ease-out;
		width: 1rem;
		height: 1.5rem;
		@include hover_transition;
	}
	&:hover {
		color: $isDark;
		&::after {
			right: -1.5rem;
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33337 8H12.6667' stroke='%232D3748' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.33337L12.6667 8.00004L8 12.6667' stroke='%232D3748' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		}
	}
}

// Transitions
@mixin hover_transition($speed: .3s) {
	transition: all $speed ease-in-out;
	-webkit-transition: all $speed ease-in-out;
}

@mixin hover_opacity {
	opacity: 0.7;
	@include hover_transition;
}

@keyframes fadein {
	40% { opacity:0; }
	70% { opacity:1; }
}

@keyframes fadeup {
	0% { transform: translate(0, 1.2rem); }
	100% {transform: translate(0, 0); }
}

@mixin hideScroll {
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar { 
		display: none;
	}
}

@mixin transform($t) {
	-webkit-transform:$t;
	-moz-transform:$t;
	-ms-transform:$t;
	-o-transform:$t;
	transform:$t;
}
