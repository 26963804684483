body.ionic {
	
	#main-menu {
		padding-top:calc(env(safe-area-inset-top) + 10px);
	}

	#page-wrap {
		> main {
			padding-bottom:calc(env(safe-area-inset-bottom) + 2em);
		}
	}
	&.home {
		#page-wrap {
			> main {
				padding-bottom:calc(env(safe-area-inset-bottom));
			}
		}
	}
	#app {
		height:100vh;
		overflow-y:scroll;
		overflow-x:hidden;
	}
	
	.bm-item-list {
		display:flex;
		flex-direction:column;
		height:auto !important;
		min-height:100%;
	}
	
	
	::-webkit-scrollbar { 
	    display: none; 
	}
	
	.bm-menu {
		-webkit-overflow-scrolling:touch;
		padding-top: env(safe-area-inset-top);
		padding-bottom: env(safe-area-inset-bottom);
	}
	
	.support-actions {
		position:fixed;
	}

	main {
		overflow:scroll;
	}
}
