$ilegMajestic: #7a5fc4;
$ilegMajesticDarker: #6e4ec7;
$ilegSlate: #dad9d7;
$ilegGold: #e4a91c;
$ilegImperial: #230871;
$ilegFooterGray: #262626;
$ilegFooterBlue: #1C1544;
$ilegLightGray: #F3F2F2;
$font1: "GeneralSansRegular", "Helvetica Neue", Arial, Helvetica, sans-serif;
$font2: "HelveticaLight", "Helvetica Neue", Arial, Helvetica, sans-serif;

body.ileg {
	font-family: $font2;
	#main-menu {
		background:$ilegMajestic !important;

        * {
			color: white;
		}

        .logo {
			position: relative;
			max-width: none;
            height: 80px;
            padding: 5px 0;
			// bottom: -28px;
			@include sm {
				height:60px;
			}
		}
        .user-menu {
			font-family: $font1;
			.menu { 
				background:$ilegMajestic;
			}
		}

        .item.main {
            font-family: $font1;
			font-weight: normal;
        }
        
	}

	#page-wrap > main {
		padding-bottom: 0;
	}

	section {
		> .container {
			@apply py-8;
		}
	}

	h1,h2,h3,h4,h5 {
		font-family: $font1;
		font-weight: normal;
	}

	.home h1 {
		@apply tracking-wide md:text-4xl text-3xl;
		color: $ilegImperial;	
	}

	h3.process {
		color: $ilegImperial;
	}

	.home h3.process {
		font-weight: bolder;
	}

	.lawyer h3.process {
		font-weight: normal;
	}

	h1.hero {
		@apply xl:text-5xl;
	}

	.request-button {
		@apply inline-block font-normal px-9 py-2 rounded-3xl text-lg;
		background-color: $ilegImperial;
		color: $ilegGold;
	}

	.request-button:hover {
		color: $ilegGold;
		background-color: $ilegMajesticDarker;
	}

	.text-footer-blue {
		color: $ilegFooterBlue;
		
	}

	.bg-footer-blue {
		background-color: $ilegFooterBlue;
	}

	.text-footer-gray {
		color: $ilegFooterGray;
	}

	.bg-footer-gray {
		background-color: $ilegFooterGray;
	}

	.text-slate {
		color: $ilegSlate;
	}

	.bg-slate {	
		background-color: $ilegSlate;
	}

	.text-majestic {
		color: $ilegMajestic;
	}

	.bg-majestic {
		background-color: $ilegMajestic;
	}

	.text-gold {
		color: $ilegGold;
	}

	.bg-gold {
		background-color: $ilegGold;
	}

	.text-imperial {
		color: $ilegImperial;
	}

	.bg-imperial {
		background-color: $ilegImperial;
	}

	.bg-lightgray {
		background-color: $ilegLightGray;
	}

	.font-gsr {
		font-family: $font1;
	}

	.font-hl {
		font-family: $font2;
	}
}