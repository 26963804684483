.UniForm {

	.input_holder{
		label {
			.required {
				display: none;
			}
		}

		&.error {
			label{
				.required {
					display: inline;
					color:$colorError;
				}
			}
			.input {
					@include textInput(90%);
					border: 1px solid $colorError;
					color: $colorError;
			}
			.input.textarea {
					@include textInput(95%);
					border: 1px solid $colorError;
					height: 100px;
					line-height:1.5em;
					padding-top:10px;
			}
			::placeholder {
			    color:    $colorError;
			}
		}

		&.select_option.error select{
		  border: 1px solid $colorError;
		  //box-shadow:0px 4px 0px 0px $colorError;
		  color: $colorError;
		}			

	}
}
