$cmg10: #f5f5f0;
$cmg20:#d3d4cf;
$cmg50: #ced0c2;
$cmg100:#9da085;
$cmg120: #7c7d6d;
$cmg150: #444e3e;
$cmg200:#50523d;
$cmg250:#282a1d;
$cmg300: #231f20;

$fontMain: "ArgentCF", sans-serif;
$fontSecondary: "PlusJakartaSans-Regular", sans-serif;
$fontSecondaryLight: "PlusJakartaSans-Light", sans-serif;

@mixin cta {
    width: fit-content;
    padding: .5em 2.2em;
    @include title;
    @include hover_transition;
    @include sm {
        padding: .5em 1.5em;
        font-size: .9rem;
    }
}
@mixin cta_sm {
    padding: .7em 1.5em;
    margin: 0 10px;
    border-radius: 1.2em;
    font-size: 0.75rem;
    text-transform: uppercase;
}
@mixin title {
    font-family: $fontSecondary;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 1px;
}
@mixin heading {
    font-family: $fontMain;
    font-size: 2.6rem;
    font-weight: normal;
    color: $cmg300;
    line-height: .98;
    @include sm {
        font-size: 1.8rem;
    }
}
@mixin p {
    font-family: $fontSecondary;
    font-size: 1.15rem;
    line-height: 1.5rem;
}
@mixin font-sm {
    font-size: .7rem;
    line-height: 1.2;
}

body.icann.cmg {
    #page-wrap > main {
        background:white!important;
        @include sm {
            background-image: linear-gradient(rgba(122, 159, 183, 0.07), white)!important;
        }
    }
    &:not(.profile):not(.history):not(.DocConsICann-cmg) #page-wrap > main {
        padding-bottom: 0;
    }

    h1 {
        font-family: $fontMain;
        letter-spacing: 1px;
        font-weight: normal;
    }
    h2 {
        @include heading;
    }
    h3 {
        font-family: $fontMain;
        font-size: 2rem;
        font-weight: normal;
        line-height: 1.3;
        @include sm {
            font-size: 1.9rem;
        }
    }
    h4 {
        font-family: $fontSecondaryLight;
        line-height: 1.3;
        font-size: 1.8rem;
        font-weight: normal;
        color: $cmg300;
        @include sm {
            font-size: 1.2rem;
        }
    }
    h5 {
        color: $cmg300;
        @include title;
    }
    a {
        font-family: $fontSecondary;
        @include hover_transition;
        &:hover {
            color: $cmg120;
        }
    }
    .ui.button {
        font-family: $fontSecondary;
        font-size: 0.9rem;
    }
    p, li {
        color: $cmg300;
        @include p;
    }
    .onboarding   {
        p {
            font-size: 1rem;
        }
    }
    .signup i.question.circle.outline.icon {
        display: none;
    }

    &.faq li {
        margin-bottom: 1em;
        > ul li {
            margin-bottom: 0;
        }
    }
    .meds, &.profile, &.history {
        h1 {
            font-size: 2.5rem;
        }
        h2 {
            font-size: 2rem;
            @include sm {
                font-size: 1.6rem;
            }
        }
        h3 {
            font-size: 1.6rem;
            @include sm {
                font-size: 1.4rem;
            }
            &.dec {
                font-family: $fontSecondary;
                font-size: 1.2rem;
                @include sm {
                    font-size: 1rem;
                }
            }
        }
        h4 {
            font-size: 1.5rem;
            @include sm {
                font-size: 1.2rem;
            }
        }
        h5 {
            font-size: 1.2rem;
            @include sm {
                font-size: 1.1rem;
            }
        }
        .ui.item.dropdown, .list-pagination {
            font-size: 1rem;
            @include sm {
                font-size: .9rem;
            }
        }
    }
    .user_area {
        .cmg-consent {
            label {
                font-size: 0.875rem;
                line-height: 1.0625rem;
            }
            .required {
                display: flex;
                .field {
                    width: 50%;
                    margin-bottom: 0;
                }
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            li {
                width: 50%;
                font-size: 0.781rem;
            }
        }
    }
    .login-banner h4 {
        font-size: 1.07rem;
        font-weight: 600;
    }
    .list-pagination {
        font-size: 1.1rem;
    }
    .intercom-lightweight-app-launcher {
        background: $cmg120;
    }
    br.lg {
        display: none;
        @include lg {
            display: inline;
        }
    }
    .ui.modal > .content {
        a {
            cursor: pointer;
        }
        ul {
            list-style: disc;
        }
    }
    .active {
        border: none!important;
    }

    // Main Menu
    #main-menu {
        background:white !important;

        .item.burger {
            padding-right: 0;
        }
        .user-menu span:hover {
            background: none;
        }

        .logo {
            position: relative;
            max-width: none;
            height: 35px;
            width: auto;
            margin:.5em 0;
            @include sm {
                height:30px;
            }
            &:hover {
                opacity: 0.6;
            }
            @include sm {
                height: 25px;
            }
        }
        &:not(.extra.user-in) {
            .item {
                text-transform: uppercase;
                font-size: 0.75rem;
                font-weight: 500;
                letter-spacing: 1.1px;
            }
        }
        .item {
            font-family: $fontSecondary!important;
            line-height: 1;
            @include hover_transition;
            &:not(.get-started):not(.sign-in) {
                color: $cmg200;
                padding: .5rem 1.0625rem;
                &:hover {
                    color:$cmg100;
                    background: none;
                }
            }
        }
        .get-started, .sign-in {
            @include cta_sm;
            @include hover_transition;
            font-family: $fontSecondary;
            .active {
                pointer-events: none;
            }
            &:hover, &.active {
                background-color: $cmg50;
                color: #fff;
            }
        }
        .get-started {
            background-color: $cmg150;
            color: #fff;
        }
        .sign-in {
            background-color: $cmg20;
            color: $cmg200;
        }
        .container > .menu {
            padding: .8em 0;
        }
    }
    // Mobile Menu
    .bm-menu, .bm-menu .ui.inverted.menu {
        background-color: $cmg200;
        a {
            font-family: $fontSecondary;
            text-transform: uppercase;
            font-weight: normal;
            font-size: .9rem;
        }
    }

    // Icons
    .st0 {
        fill: $cmg200;
    }
    .st1 {
        fill:none;
        stroke:#FFFFFF;
        stroke-width:7;
        stroke-linecap:round;
        stroke-linejoin:round;
    }

    // Hero
    .cmg-hero {
        background: $cmg250 url('https://storage.googleapis.com/instant-med-public/Haiku.Health/haiku-home-hero.jpg') no-repeat;
        background-size: cover;
        height: 100vh;
        @include sm {
            height: 60vh;
        }
        h1 {
            color: white!important;
            font-size: 3.2rem;
            @media ( min-width: 1024px) {
                font-size: 5rem;
            }
        }
    }
    .cta-main {
        margin: 0 auto;
        @include hover_transition;
    }

    .process img {
        box-shadow: none;
    }

    // Steps
    .steps-panel {
        width: 285px;
        height: 285px;
        p, h2 {
            color:$cmg10;
        }
        h2 {
            font-size: 2rem;
            margin-bottom: .8rem;
            line-height: 1;
        }
        
        &:first-child {
            background-color: $cmg100;
            h3 {
                color: $cmg10;
            }
        }
        &:nth-child(2) {
            background-color: $cmg120;
        }
        &:nth-child(3) {
            background-color: $cmg200;
        }
        &:last-child {
            background-color: $cmg250;
        }
        img { display: none; }
    }

    .text-step {
        p, h2 {
            font-family: $fontSecondary;
        }
        p {
            font-size: 14.4px;
            div {
                font-size: .6rem;
                padding: 0 .8em 0;
            }
        }
        h2 {
            font-size: 1.85rem;
            font-weight: 500;
            margin-bottom: .4rem;
        }
        h3 {
            font-size: 1.7rem;
            font-family: $fontSecondary;
            margin-bottom: .4rem;
            line-height: 1.1;
            font-weight: 600;
        }
        h4 {
            font-size: 1.3rem;
            font-family: $fontSecondary;
        }
    }

    .hero {
        background-color: transparent;
        color: $cmg250;
        margin: 2em 0;
        p {
            font-weight: normal;
        }
        @include sm {
            margin: 1.5em 0;
        }
    }
    .btn-info {
        @include font-sm;
    }
    // Pages
    .home {
        .cmg-hero .cta-main {
            background-color: $cmg10;
            color: $cmg300;
            &:hover {
                background-color: lighten($cmg120, 10%);
                color: #fff;
            }
            &.mb-4{
                margin-bottom: 2rem;
            }
        }
        .process {
            margin: 0 auto!important;
            p {
                font-size: .95rem;
                line-height: 1.3;
                letter-spacing: .5px;
            }
            .text-step {
                h4 { color: $cmg100; }
                &:first-of-type h4 {
                    color: $cmg10;
                }
                p {
                    font-size: .85rem;
                    padding: 0 1.25rem;
                    line-height: 1.2rem;
                }
                h3 {color: $cmg10;}
            }
            .grid {
                margin: 0 -0.5rem;
            }
        }
        .info {
            @include lg  {
                h4 {
                    font-size: 1.68rem;
                }
            }
        }
    }

    .text-image {
        p {
            font-family: $fontSecondary;
        }
        h2 {
            font-size: 2.4rem;
            @include sm {
                font-size: 1.85rem;
            }
        }
        h2:first-of-type {
            margin-bottom: 0;
        }
        .secondary {
            color: $cmg100;
        }
        .cta-main {
            margin: 1rem 0 .5rem 0;
            display: block;
        }
    }

    // accordion faq
    .faq .ui.styled.accordion {
        width: 100%;
        background-color: transparent;
        box-shadow: none;
        margin: 0 auto;
        .dropdown.icon {
            position: absolute;
            right:0;
            content: url("https://storage.googleapis.com/instant-med-public/Haiku.Health/arrow.svg");
            opacity: .8;
        }

        .title, .active.title {
            padding: 1em;
            font-weight: normal;
            position: relative;
            border-bottom: .5px solid $cmg100;
            color: $cmg200;
            @include p;
        }
        .active.title {
            color: $cmg200;
            .dropdown.icon {
                transform: rotate(180deg);
            }
        }
        .content p, li {
            color: $cmg300;
            font-family: $fontSecondaryLight;
        }
    }

    .aboutus {
        .cta-main {
            background-color: $cmg10;
            color: $cmg300;
            @include hover_transition;
            &:hover {
                background-color: lighten($cmg120, 10%);
                color: #fff;
            }
        }
        .secondary {
            margin-top: 0;
        }
        .cmg-hero  {
            background-image: url('https://storage.googleapis.com/instant-med-public/Haiku.Health/haiku-shadows-lg.jpg');
        }
    }
    

    .patient .text-image {
        .secondary {
            color: $cmg200;
            margin-top: 0;
        }
    }
       
    h1 {
        @include heading;
    }

    .getstarted {
        .pricing {
            background-color: #fff;
        }
        a {
            font-family: $fontSecondary;
            font-weight: normal;
        }
        .grid > div {
            background-color: transparent;
            box-shadow: none;
        }
        .process > div {
            @include sm {
                padding-bottom: 0;
            }
        }
        p {
            font-family: $fontSecondary;
            font-size: 1.06rem;
            line-height: 1.3rem;
        }
        .eligibility p {
            font-size: 1.18rem;
            line-height: 1.45rem;
        }
        .panels {
            > div {
                &:nth-child(1){
                    .step, .cta-light {
                        background-color: $cmg200;
                    }
                }
                &:nth-child(2){
                    .step, .cta-light {
                        background-color: $cmg120;
                    }
                }
                &:nth-child(3){
                    .step, .cta-light{
                        background-color: $cmg120;
                    }
                }
                p {
                    padding: 1rem 2.5rem;
                }
            }
            .panel-btn {
                width: 200px;
                margin-top: auto;
                &.active:hover {
                    background-color: lighten($cmg100, 8%);
                }
                &:not(.active) {
                    color: $cmg50;
                }
            }
        }
    }

    div {
        min-height: 0!important;
    }

    .patient h1 {
        font-size: 3.1rem;
        @include sm {
            font-size: 1.8rem;
        }
    }
    .indications {
        .text-image h2:first-of-type {
            margin-bottom: 1rem;
        }

        .tiles {
            padding-top: 100%;
            height: 0;
            position: relative;
            overflow: hidden;
            h4 {
                color: $cmg200;
                font-weight: 700;
                font-size: 1.3rem;
                @include sm {
                    font-size: 1rem;
                }
            }
        }
    }
    #popup-login-cont {
        margin-top: .5rem;

        @include sm {
            margin-top: 1rem;
        }
    }
    .login-page {
        grid-template-columns: none;
        padding: 10vh 0;
        @include lg {
            padding: 20vh 0;
        }
        a, label, span {
            font-family: $fontSecondary;
            font-size: .9rem;
        }
        h5 {
            margin-top: 0;
        }

        background:#F6F6F7 url('https://storage.googleapis.com/instant-med-public/Haiku.Health/haiku-home-header.jpg') 0 0 no-repeat;
        background-size:cover;
        background-position: center;
        background-attachment: fixed;

        h3 {
            font-family: $fontBold;
            font-size: 1.7rem;
            color: $cmg10;
        }
        .bg-is-blue2 {
            background-color: $cmg150;
        }
        .text-is-blue {
            color: $cmg150;
        }
    }

    .cta {
        @include hover_transition;
        &-main {
            @include cta;
            &:hover {
                color: #fff;
                background-color: $cmg100;
            }
        }
        &-light {
            color: #fff;
            background-color: $cmg150;
            @include cta;
            @include hover_transition;
            &:hover {
                color: white;
            }
        }
    }
    .ui.green.button {
        background-color: $cmg150;
    }
    .ui.blue.button, .ui.orange.button {
        background-color: $cmg120;
    }
    .ui.primary.button {
        background-color: $cmg200;
        color: #fff;
    }
    .ui.button:hover {
        color: #fff;
        background-color: $cmg100;
    }

    .complete-panel>.segment {
        padding-bottom:1rem;
	    margin-bottom:2rem;
        border:2px solid $cmg250 !important;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .10), 0 10px 10px -5px rgba(0, 0,0, .04) !important;
    }

    .pricing {
        background-color: #d4cec0;
        p {
            font-size: 1rem;
        }
    }

    .terms, .privacy {
        h1 {
            font-size:2.1rem;
        }
        h3 {
            font-size:1.5rem;
            font-weight: bold;
        }
    }
    .support-tiles {
        margin: 0 auto;
        width: 100%;
        @include xl {
            width: 75%;
        }
        h3 {
            font-size: 2rem;
            @include title;
        }
        h4 {
            font-size: 1.2rem;
            @include title;
            font-family: $fontSecondary;
        }
        p {
            font-size: 1.18rem;
            font-family: $fontSecondary;
        }
        a {
            font-family: $fontSecondary;
            cursor:pointer;
            &:hover {
                color: $cmg120;
            }
        }
    }

    .footer {
        background-color: $cmg200;

        a, p {
            color: #fff;
            font-family: $fontSecondary;
        }
        a {
            text-decoration: underline;
            text-underline-position: under;
            text-decoration-color: transparent;
            font-size: .85rem;
            &:hover {
                color: $cmg50;
                text-decoration-color: $cmg50;
                @include hover_transition;
            }
        }
        li {
            text-transform: uppercase;
        }
        p, p a {
          @include font-sm;
        }
        i {
            font-style: normal;
        }
        img {
            width: 140px;
        }
    }
}

