
$colorError : #f00;

$tw_grey:#707070;
$tw_dark:#2e3745;
$tw_shadow:#DBDBDB;
$footer_bg:$tw_dark;
$menu_bg:#2D3748;
$isDark: #2D3748;
$isBrandColor:#0065F2;
$isBrandHover:#1377F7;
