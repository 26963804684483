
html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.home-iscr {
    flex:1 1 auto;
    display:flex;
    flex-direction: column;
}

.home-section {
    .section-title {
        margin-bottom: 8px;
        color: $isBrandColor;
        &.no-user {
            color: #fff;
        }
    }
}

.home-anchor {
    visibility: hidden;
    @media screen and (max-width: 767px) {
        position: absolute;
        top: 450px;
    }
}

/*--------------------------------------------------------------
# HERO SECTION
--------------------------------------------------------------*/

.home-section-hero .section-user {
    background-repeat: no-repeat;
    color:white;
    max-width:100vw;
    overflow: hidden;

    br.lg {
        display:none;
        @include lg {
            display:inline;
        }
    }
    br.title {
        display: none;
        @apply xl:inline
    }
    p {
        &.section-title {
            font-weight:bold;
        }
        &.section-subtitle {
            &.opt-banner {
                @include md {
                    max-width: 90%;
                }
            }
        }
    }
    .row {
        padding-bottom:0 !important;
    }
    .column {
        display:inline-flex !important;
        justify-content: center;
        align-items: center;
        &.text {
            justify-content: flex-start;
            
        }
        &.pic {
            align-items:flex-end;
        }
    }
}

@media( min-width: 768px ) {
    .home-section-hero .section-main {
        padding-top: 70px;
        padding-bottom: 30px;
    }

    .home-section-hero .hero-actions {
        display: none;
    }

    .mob_only {
        display: none;
    }
}

/*--------------------------------------------------------------
# FEATURES
--------------------------------------------------------------*/
.home {
    &.iscr {
        .quiz-home-banner {
            width: 100%;
            margin-top: 40px;
            @include lg {
                margin-top: -49px;
                margin-bottom: 32px;
            }
            @media only screen and (min-width: 1380px) {
                padding: 1rem 0 0;
            }
        }
    }
}

.more-link {
    @include arrow-link;
}

//****////
$grad1:$isBrandColor;
$grad2:$isBrandColor;
$grad3:$isBrandColor;
$grad4:$isBrandColor;
$radSm:10px;
$radMd:5px;

/*--------------------------------------------------------------
# HOW IT WORKS
--------------------------------------------------------------*/

.home-section-hiw {
    text-align: center;

    .inner {
        background-color: #E2F3FF;
        max-width: 450px;
        height: 190px;
        padding: 2.5em 0.3em 1.5em;
        @include sm{
            height: auto;
        }
    }
    .section-header {
       margin: 0 auto 30px;
    }
    .ui.grid > .row > .column {
        padding: 0 0.3em;
    }
    img {
        width: 40px;
        margin: 0.4em;
    }
    @media ( max-width: 992px ) {
        padding: 0;
        p {
            margin: 0 1.8em;
        }
    }
    @media ( max-width: 767px ) {
        .ui.grid > .row > .column, .section-header {
            padding: 0 1em;
        }
        .section-header {
            margin-bottom: 1.5em;
        }
    }
}

/*--------------------------------------------------------------
# FAQ
--------------------------------------------------------------*/

.faq,
.accord-left {
    .ui.styled.accordion {
        box-shadow: none;
        margin: 0 auto;

        @include lg {
            width: 100%; // 950px;
        }

        :not(.search)>.dropdown.icon {
            position: absolute;
            right: 1rem;
            height: 1.3rem;
            width: 1.3rem;
            font-weight: bold;
        }

        .active.title .icon {
            transform: rotate(180deg);
        }

        .content {
            padding: 15px 25px 20px 25px;

            a {
                color: $isBrandColor;
                cursor: pointer;
            }

            p {
                color: #6B7280;
                line-height: 1.6;
            }

            ul {
                list-style: disc;
            }
        }

        .active {
            border: none !important;

            &:before {
                transform: rotate(-180deg);
            }
        }

        .title {
            padding: 16px 32px 16px 20px;
            color: #2D3748;
            border-bottom: 2px solid #E5E7EB;
            border-top: none;
            position: relative;
        }
    }
}

.accord-left .ui.styled.accordion {
    :not(.search) > .dropdown.icon {
        margin-right: 10px;
        scale: 1.4;
        position: relative;
    }
}

/*--------------------------------------------------------------
# REFERRAL
--------------------------------------------------------------*/

.home-section-referral .section-inner {
    position: relative;
    overflow: hidden;
    background-color: $tw_blue;
    
}

.home-section-referral .section-title {
    font-size: 20px;
    @media( min-width: 768px ) {
        font-size: 25px;
    }
}

.home-section-referral .section-image {
    min-height: 160px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;
    display:none;
}

.home-section-referral .section-image:before {
    content: '';
    display: block;
    padding-bottom: 36%;
}

.home-section-referral .section-title,
.home-section-referral .section-desc {
    color: #FFF;
}

.home-section-referral .section-actions {
    margin-top: 32px;
}

.home-section-referral .ui.button.section-action {
    color: $isBrandColor;
}

@media( max-width: 991px) {
    .home-section-referral .section-inner {
        border-radius: 0;
        overflow: hidden;
    }

    .home-section-referral .content-col {
        padding-top: 24px;
        padding-bottom: 32px;
    }
}

@media( min-width: 768px ) {
    .home-section-referral .section-image {
        display:block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 48%;
    }

    .home-section-referral .content-col {
        padding-top: 30px;
        padding-bottom: 30px;
        
        .modal & {
            padding-left:calc(1rem + 30px) !important;    
        }
    }
}

.home-section-extras{
    &.user {
        body.about & {
            display:block;
        }
        @media( min-width: 768px ) {
            display:block;
            .home-iscr.about & {
                padding-top:3em;
            }
            .home-section-hiw {
                padding-bottom:30px;
            }
        }
    }
    .cond-service-tiles {
        > div {
            padding: 2rem 0 3rem 0;
            margin: 0 auto;
            @include sm {
                padding: 1.5rem 0 3rem 0;
            }
        }
    }
}
