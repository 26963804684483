@tailwind base;
@tailwind components;
@tailwind utilities;


/* From SUI */
@layer base {

	h1,
	h2,
	h3,
	h4,
	h5 {
		margin: calc(2rem - 0.14285714em) 0em 1rem;
		padding: 0em;
	}

	h1 {
		@apply text-2xl;
		font-weight: 400;
		@media (max-width: 768px) {
			@apply text-2xl-sm;
		}
	}

	h2 {
		@apply text-xl;
		font-weight: 400;
		@media (max-width: 768px) {
			@apply text-xl-sm;
		}
	}

	h3 {
		@apply text-lg;
		font-weight: 400;
		@media (max-width: 768px) {
			@apply text-lg-sm;
		}
	}

	h4 {
		@apply text-md;
		font-weight: 500;
	}

	h5 {
		@apply text-base;
		font-weight: 500;
	}
	p {
		@apply text-base;
		margin: 0em 0em 1em;
	}
	p:first-child {
		margin-top: 0em;
	}
	p:last-child {
		margin-bottom: 0em;
	}

	.capitalize {
		text-transform:capitalize;
	}
	a.link {
		@apply underline text-blue-600 hover:text-blue-800 hover:underline;
	}
}