* {
  margin: 0;
}
html, body {
  height: 100%;
}

#app {}

#page-wrap {
	min-height:100vh !important;
	display:flex;
	flex-direction:column;
	> main {
		min-height: 60vh;
		display: flex;
		flex-direction: column;
		flex:1 1 0%;
		padding-bottom:2em;
		background: #F9FAFB;
        // background: linear-gradient(180deg, rgba(246,246,248,1) 60%, rgba(249,249,250,0) 100%);
        background-size:100% 500px;
        background-repeat: no-repeat;
		&:after {
			// Spacer for mobiles like iPhone
			content:"";
			display:block;
			padding-bottom: env(safe-area-inset-top);
		}
		> .container:first-child, .container:nth-child(2):not(.meds){
			padding-top:1rem;
		}
        body.home &,
        body.about & {
            // padding-bottom:0;
        }

	}
	
}
.home, .navig, .onepass, .pricing, .prescription {
	#page-wrap > main {
		padding-bottom: 0;
	}
}
.DocCons {
	#page-wrap > main {
		background: #F6F6F7;
		background-size: cover;
	}
}
.prescription #page-wrap>main {
	background: #FFF;
}
.wide-content {
  flex: 1;
  @media screen and (min-width:768px) {
      padding: 0px 40px;
  }
}

.line-height-tight p {
	line-height: 1;
}