$text-colors:(
	red:$redTextColor,
	orange:$orangeTextColor,
	yellow:$yellowTextColor,
	olive:$oliveTextColor,
	green:$greenTextColor,
	teal:$tealTextColor,
	blue:$blueTextColor,
	violet:$violetTextColor,
	purple:$purpleTextColor,
	pink:$pinkTextColor,
	brown:$brownTextColor,
	grey:$grey,
	error:#9F3A38
);
@each $tc,$col in $text-colors{
  .text.#{$tc}{
    color: #{$col};
  }
}
