
$blsLightGray: #fafafa;
$blsLightBlue: #7a9fb7;
$blsDarkBlue:#09384c;
$blsBlue:#0d5786;
$blsMedBlue: lighten($blsBlue, 10%);

$fontMain: "Gaylon-Book", Arial, Helvetica, sans-serif;
$fontBold: "Gaylon-Bold", Arial, Helvetica, sans-serif;
$font3: "HarmoniaSansRegular", Arial, Helvetica, sans-serif;

@mixin cta {
    font-family: $fontMain;
    width: fit-content;
    padding: 10px 35px;
    @include xl {
        padding: 10px 50px;
    }
}

body.icann.cbls {

    color: $blsDarkBlue;
    
    #page-wrap > main {
        background:white!important;
        @include sm {
            background-image: linear-gradient(rgba(122, 159, 183, 0.07), white)!important;
        }
    }
    
    h1,h2,h3,h4,h5 {
        font-family: $fontBold;
        color: $blsDarkBlue;
        font-weight: normal;
    }
    h1 {
        font-family: $fontMain;
        font-weight: bold;
        line-height: 1.1;
        font-size: 2.7rem;
        @media ( min-width: 1024px) {
            font-size: 3.8rem;
            line-height: 1.2;
        } 
    }
    h2 {
        font-size: 2.5rem;
        @include sm {
            font-size: 1.9rem;
        }
    }
    h3 {
        font-family: $fontMain;
        font-size: 2rem;
        @include sm {
            font-size: 1.6rem;
        }
    }
    h4 {
        font-family: $fontBold;
        font-size: 1.5rem;
    }
    a {
        font-family: $fontMain;
        @include hover_transition;
        &:hover {
            color: lighten($blsDarkBlue, 20%);
        }
    }
    p {
        color: $blsDarkBlue;
        font-family: $fontMain;
    }
    p.icann-content {
        @apply lg:text-md text-base;
        line-height: 1.75rem;
    }
    .msg {
        font-family: $fontMain;
    }
    .meds, &.profile, &.history {
        h1 {
            font-size: 2.5rem;
        }
        h2 {
            font-size: 2rem;
            @include sm {
                font-size: 1.6rem;
            }
        }
        h3 {
            font-size: 1.6rem;
            @include sm {
                font-size: 1.4rem;
            }
            &.dec {
                font-size: 1.2rem;
                @include sm {
                    font-size: 1rem;
                }
            }
        }
        h4 {
            font-size: 1.5rem;
            @include sm {
                font-size: 1.2rem;
            }
        }
        h5 {
            font-size: 1.2rem;
            @include sm {
                font-size: 1.1rem;
            }
        }
        .ui.button:not(.disabled):not(.basic):hover {
            background-color: darken($blsMedBlue, 15%);
            color: #FFFFFF;
        }
    }

    .getstarted h1, .support h1 {
        font-family: $fontBold;
        font-weight: normal;
        font-size: 2.5rem;
        @include sm {
            font-size: 1.9rem;
        }
    }
    .getstarted.eligibility.container {
        padding: 4rem;
        background-color: rgba(122, 159, 183, 0.5)!important;
    }

    div {
        min-height: 0!important;
    }

    #main-menu {
		background:white !important;
        * {
            color: $blsDarkBlue;
        }
        .logo {
            position: relative;
            width: auto;
            max-width: none;
            height: 62px;
            margin:.5em 0;
            @include sm {
                height:50px;
            }
            &:hover {
                opacity: 0.8;
            }
        }
        .item {
            font-family: $fontMain;
        }

        .item:not(.get-started):not(.sign-in) {
            color: $blsDarkBlue;
            @include hover_transition;
            &:hover, &.active {
                color: lighten($blsDarkBlue, 20%);
                background: none;
            }
        }

        .get-started, .sign-in {
            font-weight: 400 !important;
            padding: 10px 15px;
            margin: 0 10px;
            color: white;
            border-radius: 1.2em;
            &.active {
                background-color: lighten($blsDarkBlue, 15%);
                pointer-events: none;
            }
        }
        
        .get-started {
            background-color: $blsBlue;
            &:hover {
                background-color: $blsDarkBlue;
            }
        }

        .sign-in {
            background-color: $blsDarkBlue;
            &:hover {
                background-color: $blsBlue;
            }
        }
    }

    .bm-menu, .bm-menu .ui.inverted.menu {
        background-color: $blsDarkBlue;
    }

    .hero {
        background-color: transparent;
        color: $blsDarkBlue;
        margin: 2em 0;
        p {
            font-weight: normal;
        }
        @include sm {
            margin: 1.5em 0;
        }
    }

    .patient h1 {
        font-size: 3.1rem;
        @include sm {
            font-size: 2.5rem;
        }
    }
    #popup-login-cont {
        margin-top: .2rem;

        @include sm {
            margin-top: 1rem;
        }
    }
    .login-page {
        @include lg {
			background:#F6F6F7 url('https://storage.googleapis.com/instant-med-public/BLSClinics/BLS_login.jpg') 0 0 no-repeat;
			background-size:cover;
			background-position: center;
			background-attachment: fixed;
            h3 {
                color: #fff;
            }
		}
        h3 {
            font-family: $fontBold;
            font-size: 1.7rem;
        }
        .bg-is-blue2 {
            background-color: $blsMedBlue;
        }
        .text-is-blue {
            color: $blsMedBlue;
        }
    }
    
    .header-img {
        @media (min-width: 768px) {
            mask-image: url('/www/icann/images/hero-mask.svg');
            mask-size: contain;
            mask-repeat: no-repeat!important;
            -webkit-mask-repeat: no-repeat!important;
            margin: 0;
        }
    }

    .cta {
        @include hover_transition;
        &-main {
            color: white;
            background-color: $blsDarkBlue;
            @include cta;
            &:hover {
                color: white;
                background-color: $blsBlue;
            }
        }
        &-light {
            color: white;
            background-color: $blsBlue;
            @include cta;
            @include hover_transition;
            &:hover {
                color: white;
                background-color: $blsDarkBlue;
            }
        }
    }

    .ui.green.button, .ui.orange.button {
        background-color: $blsMedBlue;
    }
    .ui.yellow.button {
         background-color: $blsLightBlue;
    }
    .ui.primary.button {
        background-color: $blsBlue;
        color: #FFF;
    }

    .process.grid {
        margin: 0 -0.5rem;
    }

    .complete-panel>.segment {
        padding-bottom:1rem;
	    margin-bottom:2rem;
        border:2px solid $blsDarkBlue !important;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .10), 0 10px 10px -5px rgba(0, 0,0, .04) !important;
    }

    .help-option {
        @media (min-width: 768px) {
            h3 {
                height: 3.5rem;
            }
         }
    }

    .about-logo {
        max-width:240px;
    }

    .text-step {
        font-size: 1.5rem;
        font-family: $fontBold;
        color: $blsBlue;
        font-weight: normal;
    }

    .ui.item.dropdown, .list-pagination {
        font-size: 1rem;
        @include sm {
            font-size: .9rem;
        }
    }

    .ui.blue.button {
        background-color: lighten($blsDarkBlue, 20%);
        color: #FFF;
    }
    .onboarding > div {
        border-color: lighten($blsDarkBlue, 25%)!important;
    }

    .terms, .privacy {
        h1 {
            font-size:2.1rem;
        }
        h3 {
            font-size:1.5rem;
            font-weight: bold;
        }
    }

    .footer {
        font-size: .9rem;
        p, a {
            color: $blsLightGray;
        }
        a {
            text-decoration: underline;
            text-underline-position: under;
            text-decoration-color: transparent;
            &:hover {
                color: #fff;
                text-decoration-color: #fff;
                @include hover_transition;
            }
        }
    }
}
