$icannIndica: #fdf3cb;
$icannSativa: #1b4932;
$icannIndicaTint: #fbf3cb;
$icannHybrid: #a9bf88;
$icannGreen: #95a598;
$icannFooter: #2e3747;
$font1: "GeneralSansBold", "Helvetica Neue", Arial, Helvetica, sans-serif;
$font2: "adobe-handwriting-tiffany", "Helvetica Neue", Arial, Helvetica, sans-serif;
$font3: "acumin-pro", "Helvetica Neue", Arial, Helvetica, sans-serif;
$font4: "GeneralSansRegular", "Helvetica Neue", Arial, Helvetica, sans-serif;

body.icann {

    &.aboutus, &.getstarted, &.faqs, &.support  {
        #page-wrap > main {
            background: #FFF;
        }
    }

    h1,h2,h3,h4,h5 {
        font-family: $font1;
        color: $icannSativa;
    }
    h1 {
        min-height: 1rem;
        font-size: 2rem;
        line-height: 3.5rem;
        font-weight: 400;
    }
    h2 {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 400;
    }

    h3.dec {
        font-family: $font4;
    }

    h3>small {
        font-family: $font4;
    }

    a {
        font-family: $font3;
        &:hover {
            color: $icannHybrid;
        }
    }

    p {
        font-family: $font3;
        color: $icannSativa;
    }

    p.icann-content {
        @apply lg:text-icann_md text-base;
    }

    .home section>div>div, .home section>div, 
    .patient section>div>div, .patient section>div, 
    .getstarted section>div>div, .getstarted section>div,
    .aboutus section>div>div, .aboutus section>div {
        min-height: 400px;
    }

	#main-menu {
		background:white !important;

        * {
            color: $icannSativa;
        }

        *:hover {
            background-color: white;
        }

        .logo {
			position: relative;
			max-width: none;
            height: 62px;
            margin:.5em 0;
            // padding: 5px 0;
			// bottom: -28px;
			@include sm {
				height:35px;
			}
            &:hover {
                opacity: 0.8;
            }
		}

        .item {
            font-family: $font3;
        }
        
        .get-started {
            font-weight: 400 !important;
            padding: 10px 15px;
            margin: 0 10px;
            color: white;
            background-color: $icannHybrid;
            border-radius: 9999px;
        }

        .get-started:hover {
            color: white;
            background-color: $icannSativa;
        }

        .sign-in {
            font-weight: 400 !important;
            padding: 10px 15px;
            margin: 0 10px;
            color: white;
            background-color: $icannSativa;
            border-radius: 9999px;
        }

        .sign-in:hover {
            color: white;
            background-color:  $icannHybrid;
        }
        &.extra.user-in .item:not(.get-started) {
            font-size: .92rem;
        }
    }

    .hero {
        background: white;
        color: $icannSativa;

        h1 {
            font-family: $font2;
            font-weight: 400;
            font-size: 3rem;
            line-height: 1;
            
            @media ( min-width: 1024px) {
                font-size: 4rem;
            } 
        }

        h1.about-us {
            font-size: 2.9rem;
            line-height: 1;
            @media (min-width: 321px) {
                font-size: 3rem;
            }
        }

        p {
            font-family: $font3;
            font-weight: 600;
            // font-size: x-large;
        }
    }
    
    .mask {
        @media (min-width: 768px) {
            mask-image: url('/www/icann/images/hero-mask.svg');
            mask-size: contain;
            mask-repeat: no-repeat!important;
            mask-position: center;
            margin: 20px 0;
        }
    }

    .click-button {
        font-family: $font3;
        width: fit-content;
        padding: 10px 50px;
        color: white;
        background-color: $icannSativa;
        
    }

    .click-button:hover {
        color: white;
        background-color: $icannHybrid;
        @include hover_transition;
    }


    .cta-light {
        font-family: $font3;
        width: fit-content;
        display:block;
        padding: 10px 20px;
        color: white;
        background-color: $icannHybrid;
        @include xl {
            padding: 10px 50px;
        }
        @include hover_transition;
        &:hover {
            color: white;
            background-color: $icannSativa;
        }
    }

    .click-button-trans {
        font-family: $font3;
        width: fit-content;
        padding: 10px 20px;
        color: $icannIndicaTint;
        border-style: groove;
        border-color: $icannIndicaTint;
        border-width: 2px;
    }

    .click-button-trans:hover {
        background-color: $icannSativa;
    }

    .click-button-trans.green {
        color: $icannSativa;
        border-color: $icannSativa;
    }

    .click-button-trans.green:hover {
        color: $icannIndicaTint;
        background-color: $icannSativa;
    }

    .greener-future {
        p {
            text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
        }

        #arrow {
            transform: rotateY(180deg);
            img {
                transform: rotate(-115deg);
            }
        }
    }

    .icann-font-one {
        font-family: $font1;
    }

    .icann-font-two {
        font-family: $font2;
    }

    .icann-font-three {
        font-family: $font3;
    }

    .icann-font-four {
        font-family: $font4;
    }

    .bg-footer-color {
        background-color: $icannFooter;
    }
    .footer {
        a {
            text-decoration: underline;
            text-underline-position: under;
            text-decoration-color: transparent;
            text-decoration-thickness: 0.05rem;
            &:hover {
                color: #FFF;
                text-decoration-color:#FFF;
                @include hover_transition;
            }
        }
    }

    .bg-color-green {
        background-color: $icannGreen;
    }

    .bg-color-hybrid {
        background-color: $icannHybrid;
    }

    .bg-color-indicatint {
        background-color: $icannIndicaTint;
    }

    .text-color-sativa {
        color: $icannSativa
    }

    .fancy-hover {
        transition: 1s ease;
    }

    .fancy-hover:hover {
        @media (min-width: 768px) {
            -webkit-transform: scale(0.9);
            -ms-transform: scale(0.9);
            transform: scale(0.9);
            @apply shadow-sm;
        }
    }

    .login-button {
        font-family: $font3 !important;
        color: white;
        background-color: $icannHybrid !important;
    }

    .login-button:hover {
        color: white;
        background-color: $icannSativa !important;
    }

    .signup-button {
        font-family: $font3 !important;
        color: white;
        background-color: $icannSativa !important;
    }

    .sign-up:hover {
        color: white;
        background-color: $icannHybrid !important;
    }

    .complete-panel>.segment {
        padding-bottom:1rem;
	    margin-bottom:2rem;
        border:2px solid $icannSativa !important;
        box-shadow: 0 20px 25px -5px rgba(0, 0, 0, .10), 0 10px 10px -5px rgba(0, 0,0, .04) !important;
    }
    
    .panels  > div {
        a {
            display: flex;
            flex-direction: column;
            height: 100%;
            span {
                margin-top: auto;
            }
        }
    }

    .icann-complete-button {
        @media (min-width: 768px) {
           margin: 0 0 1rem 1.5rem;
        }
    }

    .help-option {
        @media (min-width: 768px) {
            h3 {
                height: 3.5rem;
            }
         }
    }
    
    .text-step {
        @apply text-lg;
    }
    .about-logo {
        max-width:240px;
    }
    .footer p {
        color: #fff;
    }
}