
$logoBlue:#0065F2;
$logoGreen:#6dc284;

$tw_blue:#0065F2;
$tw_green:#67B66F;
$tw_light_blue: #E2F3FF;
$isBrandColor:#0065F2;
@import "./home.tw.scss";
@import "./quiz.scss";

body.iscr {

	
	h1 { color:$isBrandColor; }
	
	.homepage {
		h2 { color:$isBrandColor; }
	}

	.ui.block.header {
		background:transparent;
	    padding: 0.5rem 0rem;
	    box-shadow: none;
		border:none;
	    border-bottom: 2px solid $isBrandColor;
	    border-radius: 0;
	    font-weight: 700;
	    color: $isBrandColor;
	    margin-bottom: 2rem;
	}
	.slider-container {
		@include hideScroll
	}
}
