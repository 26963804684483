@mixin grid-doubling {
	.ui.doubling.grid {
    width: auto;
  }
  .ui.grid > .doubling.row,
  .ui.doubling.grid > .row {
    margin: 0em !important;
    padding: 0em !important;
  }
  .ui.grid > .doubling.row > .column,
  .ui.doubling.grid > .row > .column {
    display: inline-block !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    margin: 0em;
  }
  .ui[class*="two column"].doubling.grid > .row > .column,
  .ui[class*="two column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="two column"].doubling.row.row > .column {
    width: 100% !important;
  }
  .ui[class*="three column"].doubling.grid > .row > .column,
  .ui[class*="three column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="three column"].doubling.row.row > .column {
    width: 50% !important;
  }
  .ui[class*="four column"].doubling.grid > .row > .column,
  .ui[class*="four column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="four column"].doubling.row.row > .column {
    width: 50% !important;
  }
  .ui[class*="five column"].doubling.grid > .row > .column,
  .ui[class*="five column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="five column"].doubling.row.row > .column {
    width: 33.33333333% !important;
  }
  .ui[class*="six column"].doubling.grid > .row > .column,
  .ui[class*="six column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="six column"].doubling.row.row > .column {
    width: 33.33333333% !important;
  }
  .ui[class*="seven column"].doubling.grid > .row > .column,
  .ui[class*="seven column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="seven column"].doubling.row.row > .column {
    width: 33.33333333% !important;
  }
  .ui[class*="eight column"].doubling.grid > .row > .column,
  .ui[class*="eight column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="eight column"].doubling.row.row > .column {
    width: 25% !important;
  }
  .ui[class*="nine column"].doubling.grid > .row > .column,
  .ui[class*="nine column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="nine column"].doubling.row.row > .column {
    width: 25% !important;
  }
  .ui[class*="ten column"].doubling.grid > .row > .column,
  .ui[class*="ten column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="ten column"].doubling.row.row > .column {
    width: 20% !important;
  }
  .ui[class*="eleven column"].doubling.grid > .row > .column,
  .ui[class*="eleven column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="eleven column"].doubling.row.row > .column {
    width: 20% !important;
  }
  .ui[class*="twelve column"].doubling.grid > .row > .column,
  .ui[class*="twelve column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="twelve column"].doubling.row.row > .column {
    width: 16.66666667% !important;
  }
  .ui[class*="thirteen column"].doubling.grid > .row > .column,
  .ui[class*="thirteen column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="thirteen column"].doubling.row.row > .column {
    width: 16.66666667% !important;
  }
  .ui[class*="fourteen column"].doubling.grid > .row > .column,
  .ui[class*="fourteen column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="fourteen column"].doubling.row.row > .column {
    width: 14.28571429% !important;
  }
  .ui[class*="fifteen column"].doubling.grid > .row > .column,
  .ui[class*="fifteen column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="fifteen column"].doubling.row.row > .column {
    width: 14.28571429% !important;
  }
  .ui[class*="sixteen column"].doubling.grid > .row > .column,
  .ui[class*="sixteen column"].doubling.grid > .column:not(.row),
  .ui.grid > [class*="sixteen column"].doubling.row.row > .column {
    width: 12.5% !important;
  }
}

@mixin grid-stackable {
	.ui.stackable.grid {
    width: auto;
    margin-left: 0em !important;
    margin-right: 0em !important;
  }
  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.grid > .stackable.stackable.row > .column {
    width: 100% !important;
    margin: 0em 0em !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    padding: 1rem 1rem !important;
  }
  .ui.stackable.grid:not(.vertically) > .row {
    margin: 0em;
    padding: 0em;
  }

  .ui.stackable.grid > .column:not(.row).margin-x-sm {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .ui.stackable.grid > .column:not(.row).padding-top-sm {
    padding-top: 0 !important;
  }
  
/* Coupling */
  .ui.container > .ui.stackable.grid > .column,
  .ui.container > .ui.stackable.grid > .row > .column {
    padding-left: 0em !important;
    padding-right: 0em !important;
  }
  
/* Don't pad inside segment or nested grid */
  .ui.grid .ui.stackable.grid,
  .ui.segment:not(.vertical) .ui.stackable.page.grid {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  
/* Divided Stackable */
  .ui.stackable.divided.grid > .row:first-child > .column:first-child,
  .ui.stackable.celled.grid > .row:first-child > .column:first-child,
  .ui.stackable.divided.grid > .column:not(.row):first-child,
  .ui.stackable.celled.grid > .column:not(.row):first-child {
    border-top: none !important;
  }
  .ui.inverted.stackable.celled.grid > .column:not(.row),
  .ui.inverted.stackable.divided.grid > .column:not(.row),
  .ui.inverted.stackable.celled.grid > .row > .column,
  .ui.inverted.stackable.divided.grid > .row > .column {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .ui.stackable.celled.grid > .column:not(.row),
  .ui.stackable.divided:not(.vertically).grid > .column:not(.row),
  .ui.stackable.celled.grid > .row > .column,
  .ui.stackable.divided:not(.vertically).grid > .row > .column {
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .ui.stackable.celled.grid > .row {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  .ui.stackable.divided:not(.vertically).grid > .column:not(.row),
  .ui.stackable.divided:not(.vertically).grid > .row > .column {
    padding-left: 0em !important;
    padding-right: 0em !important;
  }
}