@charset "UTF-8";

.select_row:hover {
    background-color:#cef !important;
    cursor: pointer;
}

.select_row:nth-child(even) {
    background-color: #f8f8f8;
}


.select_row > td:first-child {
    > span { 
        font-weight: bold;
    }
    div {
        margin-top:.2em;
    }
}

.field_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.field_cls {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 0.25em !important;
    @media screen and (min-width: 768px) {
        flex-direction: row;
        flex: 1 0 25%;
    }
    .field_wrap {
        flex:1 1 100%;
        display: flex;
        flex-wrap: nowrap;
    }
        
    .ui.label {
        background:transparent;
        font-weight:normal;
        align-items: center;
        display:flex;
        padding-left:0;
        color:inherit;
    }
    &.required {
        > .ui.label {
            font-weight:bold;
        }
    }
}

.field_input {
    //background-color: red;
    //width: 300px;
    //width: fit-content;
    //width: 100%;
    flex-grow: 1;
}

.field_check {
    display: block;
}

.field_label {
    //width: 100px;
    display: flex;
    //flex: 1;
    width: 175px;
}

.field_button {
    display: flex;
    flex: none;
}

.confirm_div {
    //margin: 2em;
}

.txt_valid_warning, .txt_valid_checking {
    color: orange;
    font-weight: 800;
}

.txt_valid_valid {
    color: green;
    font-weight: 800;
}

.txt_valid_invalid {
    color: red;
    font-weight: 800;
}

.txt_valid_neutral {
    color: grey;
    font-weight: 800;
}

.accordion > .content.acc_cont {
    //background-color: red;
    padding: 1em 0 !important;
}

.accordion.styled > .content.acc_cont {
    //background-color: red;
    padding: 1em 1em !important;
}

.prs_head {
    //background-color: red;
    //margin-top: 1em;
    display: flex;
    justify-content: space-between;
    align-content: flex-end;
    align-items: flex-end;
    width: 100%;
}

.prs_cont {
//     display: flex;
//     justify-content: space-between;
//     //align-content: flex-end;
//     align-items: flex-end;
}

.search_ihi_but {
    //margin-bottom: 10em !important;
    //padding-bottom: 10em !important;
}

.scroll_table{
    max-height: 15em;
    // height: 100px !important;
    overflow-y: auto;
    //overflow-x: hidden;
}

// .stick {
//     background-color: red!important;
//     position: -webkit-sticky; /* Safari */
//     position: sticky;
//     top: 0
    
// }
