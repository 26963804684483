body.iscr.navig {
    .intercom-lightweight-app-launcher {
        background-color: #1377F7;
    }
    .quiz-body {
        h1 {
            color:#FFF;
            font-weight: 700;
            font-size: 2.1875rem;
            margin: 3.5rem 0 0;
            @include sm {
              margin: 2rem 1rem 1.5rem;
              font-size: 1.75rem;
            }
        }
        .ui.message {
            @include sm {
                padding: .5rem 1rem;
            }
        }
        .ui.basic.blue.button {
            background-color: #FFF!important;
            padding: 1em;
            font-size: 1.25rem;
            line-height: 1.75rem;
            font-weight: 700;
            margin: .5rem 0;
            @include sm {
              padding: .875em 1em;
            }
            &:last-child { margin-bottom: 0; }
            &:first-child { margin-top: 0; }
            &:hover { 
              background-color: #E2F3FF!important;
              color: #0065F2;
            }
        }
        .quiz-header {
            background: #1377F7;
            color: #FFF;
            border-radius: 0;
            margin-bottom: 0;
            padding: .5em 1.5em;
            min-height: 46px;
            .icon.close {
              top: 14px;
              opacity: 1;
              line-height: 1;
              &:hover {
                color: #C5E5FF;
              }
            }
        
            .button {
              background-color: transparent!important;
              color: #FFF;
              font-size: 16px;
              display: flex;
              align-items: center;
              padding: 0;
              &::before {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33337 8H12.6667' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.33337L12.6667 8.00004L8 12.6667' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                background-position: center;
                background-repeat: no-repeat;
                background-size: calc(100% - 10px);
                border: 2px solid white;
                border-radius: 50%;
                transform: rotate(180deg);
                display: inline-block;
                transition: background-position .2s ease-out;
                width: 1.875rem;
                height: 1.875rem;
                margin-right: .5rem;
              }
              &:hover {
                background: transparent;
                color: #C5E5FF;
                &::before {
                  border-color: #C5E5FF;
                  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33337 8H12.6667' stroke='%23C5E5FF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.33337L12.6667 8.00004L8 12.6667' stroke='%23C5E5FF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }
              }
            }
        }
        .quiz-content .login-page {
            background: none;
            padding: 0;
            margin: 0!important;
            grid-template-columns: 1fr;
            > aside {
                width: 450px;
                margin-top: 0;
                @include sm {
                    width: 100%;
                }
            }
            .user_area {
                &.ui.form {
                    padding-top: 0;
                    &::before {
                      border-radius: 0.5rem;
                    }
                }
                h5 {
                    margin-top: 0;
                    color: rgba(0, 0, 0, 0.87);
                }
                h3 {
                    display: none;
                }
            }
        }
    }
}
