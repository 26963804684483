@import '../../semantic-sass/scss/globals/mixins';

$icosmColor: #a39383;
$icosmColorDark:#95785E;
$icosmColorLight: #FEF7EE;
$icosmColorLightGray:#ded7cf;
$icosmColorGray: #6D7171;
$icosmLightBlue: #d1e0ee;
$darkBg: #2D3748;
$textColor: rgba(0, 0, 0, 0.87);

$icosmFont: "DM Sans", sans-serif;
$icosmFontAlt: "Noto Serif", serif;
$icosmColorPrimary: #000;
$icosmColorSecondary: #fff;
$icosmColorTertiary: #AB0439;
$icosmColorQuaternary: #EFB69C;

@mixin font_main {
	// font-family: $icosmFont;
	font-family: "DM Sans", sans-serif !important;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
	// letter-spacing: 1px;
}

@mixin font-size($lg, $sm) {
	font-size: $lg;
	@include sm {
		font-size: $sm;
	}
}

@mixin cta {
	text-transform: uppercase;
	font-size: 0.813rem;
	font-family: $icosmFont;
	letter-spacing: 1px;
	border-radius: 2em;
	padding: .55em 1.65em;
	// margin-left: .5em;
	font-weight: normal;
	text-decoration: none;
	display: flex;
	align-items: center;
	width: fit-content;
	position:relative;
	.ui.button {
		padding: 0.78em 1.5em 0.78;
	}
}
@mixin icon($i) {
	padding-left:28px;
	&::after {
		content:'';
		line-height: 0;
		background-image:url($i);
		background-size:contain;
		background-repeat:no-repeat;
		height: 28px;
		width: 28px;
		margin:-10px 0 -10px 10px;
	}
}
@mixin nav_btn {
	height: 38px!important;
	min-width: 104px;
	font-weight: 700!important;
	justify-content: center;
	font-size: 13px;
	border-radius: 2em;
	font-weight: 600;
}
@mixin cta_primary {
	background-color: $icosmColorPrimary;
	color: $icosmColorSecondary;
	&:hover{
		background-color: lighten($icosmColorPrimary, 5%);
	}

	&.active,
	&.active:active,
	&:active {
		background-color: lighten($icosmColorPrimary, 10%);
	}
}

@mixin cta_basic {
	background-color: $icosmColorSecondary!important;
	color: $icosmColorPrimary!important;
	border-color: $icosmColorPrimary;
	-webkit-box-shadow: 0px 0px 0px 1px $icosmColorPrimary inset;
	box-shadow: 0px 0px 0px 1px $icosmColorPrimary inset;
	&:hover{
		background-color: $icosmColorPrimary!important;
		color: $icosmColorSecondary !important;
	}

	&.active,
	&.active:active,
	&:active {
		background-color: lighten($icosmColorPrimary, 10%);
	}
}

@mixin cta_basic_red {
	background-color: $icosmColorSecondary;
	color: red;
	border-color: red;
	border-color: red;
	&:hover{
		background-color: red;
		color: $icosmColorSecondary;
	}

	&.active,
	&.active:active,
	&:active {
		background-color: darken(red, 10%);
	}
}

@mixin cta_quaternary {
	color: $icosmColorPrimary !important;
	background-color: $icosmColorQuaternary !important;
	&:hover {
		background-color: darken($icosmColorQuaternary, 5%)!important;
	}

	&.active:active,
	&:active {
		background-color: darken($icosmColorQuaternary, 10%) !important;
	}
}

@mixin cta_tertiary {
	color: $icosmColorSecondary;
	background-color: $icosmColorTertiary;
	&:hover {
		background-color: darken($icosmColorTertiary, 5%)!important;
	}

	&.active:active,
	&:active {
		background-color: darken($icosmColorTertiary, 10%)!important;
	}
}

@mixin cta_secondary {
	color: $icosmColorPrimary !important;
	background-color: $icosmColorSecondary !important;
	&:hover, &:focus {
		background-color: darken($icosmColorSecondary, 5%)!important;
	}
}
@mixin label {
	color: $icosmColorPrimary;
	font-weight: 600;
	margin-bottom: .5em;
	font-size: 1.15rem;
}
@mixin divider {
	content: '';
	background-color: $icosmColorPrimary;
	position: absolute;
	top: -.25rem;
	left: 1rem;
	width: 60%;
	height: .5px;
	visibility: visible;
}
@mixin underline {
	text-decoration: underline;
	text-decoration-color: transparent;
	text-underline-position: under;
	@include hover_transition;
	&:hover {
		color: $icosmColorLight;
		background: transparent;
		text-decoration-color: $icosmColorLight;
	}
}
@mixin burger {
	color: $icosmColorLight;
	width: 3rem;
	@include lg {
		margin-left: 1rem;
	}
}

body.icosm {
	color:$icosmColorPrimary;
	.faq h2 {
		text-transform: capitalize;
	}
	&.instantcosmetics-faqs {
		.faq .ui.styled.accordion, .accord-left .ui.styled.accordion {
			$icon_trans:rotate(90deg);
			.title {
				background-color: $icosmColorPrimary !important;
				color: $icosmColorSecondary;
				border-bottom: 0;
				font-size: 22px;
				line-height: 23px;
				padding: 25px 41px 25px 41px;
				font-weight: 700;
				margin-top: 22px;
				&.active > .icon {
					transform:$icon_trans;
					-webkit-transform:$icon_trans;
					-ms-transform:$icon_trans;
				}
			}
			.content {
				font-size: 19px;
				background-color: $icosmColorSecondary;
				color: $icosmColorPrimary !important;
				padding: 25px 41px;
				@include sm {
					padding: 1rem;
					font-size: 1rem;
				}
			}
			a {
				color: $icosmColorTertiary;
			}

			i.icon.dropdown:before {
				content: url('data:image/svg+xml,<%3Fxml version="1.0" encoding="UTF-8"%3F><svg version="1.1" viewBox="0 0 24.1 24.2" xmlns="http://www.w3.org/2000/svg"><circle fill="none" stroke="%23FFF" cx="12.1" cy="12.1" r="11.6"/><path fill="%23FFF" d="M19.2,13.2c.4-.4.4-1,0-1.3l-6-6c-.4-.4-1-.4-1.3,0-.4.4-.4,1,0,1.3l5.3,5.3-5.3,5.3c-.4.4-.4,1,0,1.3.4.4,1,.4,1.3,0l6-6h0ZM4.5,13.5h14.1v-1.9H4.5v1.9Z"/></svg>');
			}
			margin-bottom: 22px;
		}
	}
	@media only screen and (min-width: 768px) and (max-width: 1371px) {
		@include grid-doubling;
	}	

	.page-cont.no-user .ui.container, #main-menu:not(.user-in) .ui.container {
		@include md {
			max-width: 1280px!important;
			width: 100%!important;
			&.wide-content {
				width: 100%!important;
			}
		}
	}

	.not-found {
		background-color: lighten($icosmColor, 25%)!important;
		.container {
			padding-top: 40vh;
			height: 100vh;
		}
	}

	#page-wrap:not(:has(.user-in)) > main, 
	&.get-started #page-wrap > main, 
	&.home #page-wrap > main {
		background: none;
		@include font_main;
		padding-bottom: 0;
	}
	main {
		background: #FFF;
		padding-bottom: 0!important;
	}
	.ui.container {
		padding: 2rem 1rem;
	}
	aside {
		max-width: 100%;
	}

	// Text
	h1, h2, h3, h4, h5, a, p {
		@include font_main;
	}
	h1 {
		color: $icosmColorPrimary;
		font-weight: 600;
		line-height: 3.875rem;
		@include font-size(3.688rem, 2.25rem);
		@include sm {
			line-height: 2.5rem;
		}
	}
	a {
		@apply hover:text-icosm-primary;
	}
	
	h2 {
		@include font-size(2.5rem, 2rem);
		font-weight: 600;
		line-height: 3.188rem;
		@include sm {
			line-height: 2.5rem;
		}
	}
	h3 {
		@include font-size(2.188rem, 1.82rem);
		font-weight: 600;
		line-height: 3rem;
		@include sm {
			line-height: 2.2rem;
		}
	}
	h4 {
		color: $icosmColorPrimary;
		@include font-size(1.7rem, 1.5rem);
		line-height: 1.2em;
	}
	.user h1,  h1.ui.header {
		font-family: $icosmFont;
		font-weight: 600;
		font-size: 2.188rem!important;
		line-height: 3rem!important;
		margin: 0 0 1.125rem 0!important;
		text-align: left;
	}
	h3.ui.header {
		margin: 1.125rem 0;
	}
	.modals h4 { 
		font-size: 1.25rem; 
	}
	p .-lg{
		font-size: 1.375rem;
		line-height: 1.688rem;
	}
	&.c, #user_signed_area, .prods, .modals, .payments, .meds, .sale {
		h2 {
			@include font-size(1.7rem, 1.5rem);
		}
		h3 {
			@include font-size(1.4rem, 1.2rem);
			color: $icosmColorPrimary!important;
		}
		h4 {
			@include font-size(1.2rem, 1rem);
		}
		.link {
			color: $icosmColor;
			text-transform: none;
			&:hover {
				color: lighten($icosmColor, 5%);
			}
		}
	}
	.popup-login  {
		h5{
			font-size: 1.4rem;
		}
		.message-bar {
			h5 {
				font-size: 1rem;
			}
			.content {
				font-size: 0.875rem;
			}
		}
	}
	p {
		line-height: 1.2;
	}
	.intercom-launcher, .intercom-lightweight-app-launcher {
		background: $icosmColorPrimary!important;
	}

	// Buttons
	button .main, 
	.link {
		// font-size: .9rem;
		color: $icosmColorLight;
		text-transform:uppercase;
		text-decoration: underline;
		text-underline-position: under;
		font-weight: 700;
		cursor: pointer;
		&:hover {
			color: $icosmColorLight;
			text-decoration-color: transparent;
			@include hover_transition;
		}
	}
	.ui.button {
		&.primary {
			@include cta_primary;
		}
		&.basic, &.inverted {
			&:not(.clean) {
				@include cta_basic;
			}
		}
		&.basic.red {
			@include cta_basic_red;
		}
		&.shop-cta-tertiary {
			&:not(.disabled) {
				@include cta_tertiary;
			}
		}
		&.round {
			border-radius: 20px;
		}
		&.compact {
			padding: 0.45rem 2.12rem;
		}
	}

	.ui.cta_quaternary {
		@include cta_quaternary;
	}

	.ui.cta_tertiary {
		@include cta_tertiary;
	}

	.cta {
		&.icon { @include icon("/www/icosm/images/arrow.png");}
		@include cta;
		&:hover{
			color: $icosmColorLight;
		}
		&.small {
			padding: .56rem .56rem .56rem 1.75rem;
		}
		&.basic.icon {
			background-color: transparent;
			color: $icosmColorDark;
			box-shadow: none!important;
			border: 1px solid $icosmColorDark;
			font-size: 14px!important;
			&.active {
				background-color: $icosmLightBlue;
				color: $icosmColorDark;
			}
			&:hover {
				background-color: lighten($icosmLightBlue, 5%);
			}
			&:before {
				display: none;
			}
			@include icon("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.4 22.5'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %2395785e; %7D .cls-1, .cls-2 %7B stroke-width: 0px; %7D .cls-2 %7B fill: %23fef7ee; %7D %3C/style%3E%3C/defs%3E%3Cellipse class='cls-1' cx='11.7' cy='11.2' rx='11.5' ry='11'/%3E%3Cpath class='cls-2' d='M19.4,12c.4-.4.4-1,0-1.4l-6.4-6.4c-.4-.4-1-.4-1.4,0-.4.4-.4,1,0,1.4l5.7,5.7-5.7,5.7c-.4.4-.4,1,0,1.4.4.4,1,.4,1.4,0l6.4-6.4ZM3.7,12.3h15v-2H3.7v2Z'/%3E%3C/svg%3E")
		}
		&.cta-primary {
			background-color:$icosmColorPrimary !important;
			color:$icosmColorSecondary;
		}
		&.cta-secondary {
			background-color:$icosmColorSecondary !important;
			color:$icosmColorPrimary;
		}
		&.cta-tertiary {
			background-color:$icosmColorTertiary !important;
			color:$icosmColorSecondary;
		}

	}

	.ui.accordion.side-menu {
		position: relative;

		.active.title .icon {
			-webkit-transform: translate(25%, 10%) rotate(90deg);
			transform: translate(25%, 10%) rotate(90deg);
		}
		
		.title {
			@apply rounded-full border border-icosm-primary bg-icosm-secondary w-full font-semibold px-4 mb-2 uppercase select-none;
			&:hover {
				background-color: lighten($icosmColorQuaternary, 18%);
				@include hover_transition();
			}
			.icon {
				position: absolute;
				right: 14px;
				@include hover_transition();
				&::before {
					display: block;
					width: 23px;
					height: 23px;
					content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: none; stroke: %23000000; stroke-width: 2px; %7D .cls-2 %7B fill: %23000000; stroke-width: 0px; %7D %3C/style%3E%3C/defs%3E%3Cellipse class='cls-1' cx='12.5' cy='12.5' rx='11.5' ry='11'/%3E%3Cpath class='cls-2' d='M19.4,13c.4-.4.4-1,0-1.4l-6.4-6.4c-.4-.4-1-.4-1.4,0-.4.4-.4,1,0,1.4l5.7,5.7-5.7,5.7c-.4.4-.4,1,0,1.4.4.4,1,.4,1.4,0l6.4-6.4ZM3.7,13.3h15v-2H3.7v2Z'/%3E%3C/svg%3E");
					filter: none;
				}
			}

			&:has(> .item-active) {
				@apply bg-icosm-primary text-icosm-secondary;
				.icon::before {
					filter: invert(1) brightness(1000%);
				}
			}
		}

		.ui.list {
			@apply ml-4;
			a {
				@apply uppercase text-sm font-semibold text-icosm-primary;
				&.active, &:hover {
					@apply underline;
				}
				@include hover_transition();
				text-decoration: underline;
				text-decoration-color: transparent;
				&.active, &:hover {
					text-decoration-color: #000!important;
				}
			}
		}
	}

	// Menus
		@include sm {
			.ui.top-menu.menu {
				justify-content: space-between;
				align-items: center;
				// padding: 0 14px;
			}
			.ui.menu:not(.vertical) .right.menu {
				margin-left: 0!important;
			}
			.ui.menu:after {
				display: none;
			}
			.sign-in {
				padding: 0!important;
			}
			.ui.inverted.menu .active.item {
				background: transparent;
				pointer-events: none;
				color: #FFF!important;
			}
		}

	#main-menu {
		// &:not(.user-in) {
		// 	background-color: $icosmColor!important;

		// 	&.scroll, &.active {
		// 		box-shadow: none;
		// 	}
		// 	.container {
		// 		.menu > .item.burger {
		// 			@include burger;
		// 		}
		// 	}
		// 	.logo {
		// 		height: 2.8rem;
		// 		@include sm {
		// 			height: 2rem;
		// 		}
		// 	}
			.join,
			.sign-in {
				@include md {
					// @include cta;
					@include nav_btn;
					color: $icosmColorSecondary;
					&.active {
						pointer-events: none;
					}
				}
			}
			.join {
				background-color: $icosmColorQuaternary !important;
				color: #000;
				@include sm{
					display: none;
				}
			}
			.sign-in {
				@include md {
					background-color: $icosmColorTertiary !important;
				}
			}
			
			
		// 	.user-menu .main-menu-cont {
		// 		i { display: none; }
		// 	}
		
		// 	.item:not(.sign-in) {
		// 		color: $icosmColorLight;
		// 		font-size: 1.2rem;
		// 		font-weight: bold;
		// 		@include underline;
		// 		&.active {
		// 			opacity: 0.7;
		// 			pointer-events: none;
		// 			&:hover {
		// 				color: $icosmColorLight;
		// 				background: transparent;
		// 				text-decoration-color: transparent;
		// 			}
		// 			.cta {
		// 				color: $icosmColor;
		// 				background-color: darken($icosmColorLight, 5%);
		// 				text-decoration: none;
		// 				opacity: 1;
		// 			}
		// 		}
		// 	}
			
		// }
	
		&:not(.user-in) {
			.ui.menu {
				&:nth-child(2) {
					display: none;
				}
			}
		}

		& {
			.ui.container {
				padding: 0 1rem;
				@include md {
					//padding: 0 55px;
				}
			}
			&.user-in .ui.container {
				@include lg {
					width: calc(100% - 55px)!important;
					margin: 0 auto;
				}
			}
			background-color: $icosmColorPrimary !important;
			.mega-menu {
				max-width: 582px;
			}
			.main-menu-cont  {
				gap: 2rem;
				margin-right: 1rem;
				// a:nth-last-child(1) {
				// 	@include cta_quaternary;
				// 	@include nav_btn;
				// 	@apply px-4;
				// 	text-decoration: none;
				// 	background:lime !important;
				// }
				.cta_quaternary {
					@include cta_quaternary;
					@include nav_btn;
					@apply px-4;
				}
				.menu {
					@include md {
						flex-wrap: wrap;
					}
					gap: 16px;
				}
				.menu_cta_secondary {
					@include cta_secondary;
					@include nav_btn;
					@apply px-4 hidden md:flex xl:hidden;
				}
				button {
					font-family: $icosmFont;
					font-weight: 600;
					letter-spacing: .17em;
					padding: .2rem;
					@include underline;
					&:focus, &:hover, &:active {
						outline: 0;
						border: 0!important;
						text-decoration-color: #FFF;
					}
					&:focus-visible {
						outline-color:transparent!important;
						padding: .2em;
						text-decoration: none!important;
					}
				}
			}
		
			.ui.menu {
				width: 100%;
				margin-right: 1rem;
				.item {
					text-transform: uppercase;
					font-size: 13px;
					font-family: $icosmFont;
					letter-spacing: 2px;
					> i.dropdown.icon {
						text-decoration: none!important;
					}
					&.dropdown .menu .item, &.dropdown .item {
						text-transform: none;
						text-decoration: none;
					}
				}
			}
			#profile {
				@media only screen and (min-width: 768px) {
					@include cta_tertiary;
					@include nav_btn;
					@apply px-4;
				}
			}
			.item.dropdown {
				color: $icosmColorLight;
				.menu { border-radius: 0.2rem;}
				&:hover {
					text-transform: uppercase;
					background: transparent;
					>.dropdown.icon {
						transform: rotate(180deg);
					}
				}
			}
			.item.main {
				color: $icosmColorLight;
				// @include underline;
			}
			.menu > .item.burger {
				@include burger;
			}
			
		}

		.header.item:hover {
			background: transparent;
		}
	}

	.sidemenu {
		@include hideScroll;
	}
	@include sm {
		.ui.popup.popup-login {
			margin-top: 1rem;
		}
	}

	// sidebar/ mobile menu
	.bm-menu {
		padding: 2rem;
	}
	.bm-menu, .ui.inverted.menu, .ui.inverted.menu {
		background: $icosmColorQuaternary;
		.divider {
			position: relative;
			margin-bottom: 0;
			&::before {
				@include divider;
			}
		}
		.item {
			position: relative;
			color: $icosmColorPrimary!important;
		}
		.header { pointer-events: none; }
	}

	.bm-menu-wrap:not(.user-in) {
		.item, .side-btn {
			&::after {
				content: '+';
				position: absolute;
				left: 0;
			}
			&.active::after {
				display: none;
			}
		}
	}
	/*.section main > .bm-menu .side-menu a {
		font-size: 1rem;
	}*/
	.bm-cross { 
		background: $icosmColor;
		&-button {
			height: 1rem;
			width: 1rem;
			margin: 1rem;
		}
	}
	
	.bm-menu-wrap {
		max-width:100vw;
		@include sm {
			width:100vw !important;
		}
		&.user-in {
			color: $icosmColorPrimary!important;
		}
		&:not(.user-in) {
			.item, .active {
				font-size: 1.2rem;
				&:last-of-type {
					font-weight: normal;
					margin-top: .5rem;
					&::before { @include divider; }
				}
			}
		}
	}

	.icosm-partners, .slider-container {
		@include hideScroll;
	}
	.slider-scroll {
		@include hideScroll;
		display: flex;
		transform: translateX(calc(20vw));
		animation: scrollImages 40s linear forwards infinite;
	  }
	  @keyframes scrollImages {
		from {
		  transform: translateX(calc(20vw));
		}
		to {
		  transform: translateX(calc(-100%));
		}
	  }

	.ui.menu .green.active.item, .ui.green.menu .active.item {
		color: $icosmColorPrimary!important;
		border-color: $icosmColorPrimary!important;
	}

	#icosm-site-footer {
		background-color: $icosmColorPrimary;
		color: $icosmColorSecondary;
		.ui.grid > .row {
			padding:0 0 2em;
			> .column {
				order:1;
				
				&:first-child {
					order:2;
					padding-top:2em;
					@include lg {
						order:1;
						padding-top:0em;
					}
				}
			}
		}
		
		.logo {
			height:20px;
			width:220px;
		}
		.menu-items .item {
			margin: .5rem 0;
			line-height: 1.2;
			font-size: 1em;
			@include underline;
			@include sm {
				background-color: transparent;
				color: $icosmColorSecondary;
				text-transform: capitalize;
				font-weight: bold;
				margin-right: 0;
			}
		}
		a {
			margin: 0 0.7rem 0 0;
			cursor: pointer;
			&:has(.logo) {
				margin-left: 0;
			}
		}
		
		a {
			&:hover {
				color: $icosmColorSecondary;
			}
		}
		
	}

	.popup-login {
		margin-top: .5em;
		@include sm {
			margin-top: 1em;
		}
	}

	//Components
	.mark-hero {
		
		h1 {
			-webkit-animation: .8s ease 0s normal forwards 1 fadeup;
			animation: .8s ease 0s normal forwards 1 fadeup;
			color: $icosmColorPrimary;
			font-weight: 600;
			@include font-size(3.688rem, 2.25rem);
			line-height: 3.875rem;
			@include sm {
				max-width: 100%;
				line-height: 43px;
			}
		}
		.sub {
			font-family: $icosmFontAlt!important;
			color:$icosmColorTertiary;
			font-style: italic;
			font-size:1.5em;
		}
	}
	.hero {
		@include lg {
			height: 80vh;
		}
		
		.container {
			margin-bottom: 90px;
			@include sm {
				margin-bottom: 40px;
			}
		}
	}
	

	.image-link {
		height: 100%;
		width: 100%;
		background-size: auto 100%; 
		background-position: center;
		background-repeat: no-repeat;
		@include hover_transition;
		&:hover {
			cursor: pointer;
			filter: brightness(90%);
		}
		h3 {
			color: $icosmColorLight;
			font-size: 1.8rem;
			text-decoration: none;
		}
		.link {
		   font-size: 0.8rem;
		}
		.inner {
			width: 85%!important;
			max-width: 85%!important;
			margin: 0 auto;
		}
	}

	.tiles  {
		.column{
			height: 100%;
		}
		h3 {
			font-size: 30px;
			line-height: 36px;
		}
	}
	// .tiles.multi .grid-col-1{
	// 	&:nth-child(1), &:nth-child(4) {
	// 		background-color: $icosmLightBlue;
	// 		color: $icosmColorDark;
	// 	}
	// 	&:nth-child(2), &:nth-child(5) {
	// 		background-color: $icosmColor;
	// 		color: $icosmColorLight;
	// 	}
	// 	&:nth-child(3),&:nth-child(6)  {
	// 		background-color: $icosmColorLight;
	// 		color: $icosmColorDark;
	// 	}
	// }

	.ui.block.header {
		background:transparent;
		padding: 0.5rem 0rem;
		box-shadow: none;
		border:none;
		border-bottom: 2px solid $icosmColor;
		border-radius: 0;
		font-weight: 700;
		color: $icosmColor;
		margin-bottom: 2rem;
		cursor: pointer;
	}
	.onboarding > div {
		border: 2px solid $icosmColor!important
	}

	hr {
		border-top: 0.5px solid $icosmColor;
		margin-bottom: 1.2em;
		opacity: .5;
	}
	br.lg {
        display:none;
        @include lg {
            display:inline;
        }
    }
	.message-bar {
		h3 {
			font-size: 2rem;
		}
	}

	.ui.checkbox input:checked ~ label {
		&:after {
			font-size: 1rem;
			line-height: 1;
		}
		&:before {
			background-color: $icosmColorQuaternary;
		}
	}
	.ui.inverted.green.segment {
		background-color: $icosmColorTertiary!important;
		color: $icosmColorSecondary;
	}
	.ui.inverted.segment > .ui.header {
		color: $icosmColorSecondary;
		background-color: $icosmColorTertiary!important;
	}

	// Pages
	.home .hero {
		background-image:url("https://storage.googleapis.com/icosm/2/IC-hero-home.jpg");
		@include sm {
			background-image: none;
			background-color: $icosmColorQuaternary;
		}
		@include md {
			height: 690px;
		}
	}

	.list li {
		display: flex;
		align-items: center;
		&::before {
			content: '';
			display: inline-block;
			height: 1.2rem;
			width: 1.2rem;
			margin-right: .5rem;
		}
		&.list-arrow::before {
			background: url('https://storage.googleapis.com/icosm/2/arrow-peach.png') no-repeat;
			background-size: contain;
    }
		@include sm {
			align-items: flex-start;
			&::before {
				height: 1rem;
				width: 1rem;
				margin-top: .4rem;
			}
		}
	}

	.partners {
		.hero {
			background-image:url("https://storage.googleapis.com/icosm/hero-partners.jpg");
			@include sm {
				background-image:url("https://storage.googleapis.com/icosm/hero-partners-sm.jpg");
			}
		}
		.column > div img {			
			@include sm {
				width: 50%;
				margin: 0 auto;
			}
		}
		.ui.container{
			padding-top: 4rem;
		}
	}

	.team {
		.hero {
			background-image:url("https://storage.googleapis.com/icosm/hero-team.jpg");
			@include sm {
				background-image:url("https://storage.googleapis.com/icosm/hero-team-sm.jpg");
				background-position: center;
			}
		}
		.info {
			h3 {
				margin-top: 0;
			}
			.meta {
				text-transform: uppercase;
				margin-top: 0;
				margin-bottom: .5rem;
				font-weight: 700;
			}
			.row {
				padding: 2em 0;
				&:nth-child(2n) {
					direction: rtl;
					.team-desc {
						direction: ltr;
						text-align: right!important;
					}
				}
				img {
					height: 286px;
					width: 202px;
					@include md {
						height: 370px;
						width: 250px;
					}
				}
			}
		}
	}

	.how-we-work {
		.hero {
			background-image: url("https://storage.googleapis.com/icosm/hero-how-we-work.jpg");
			@include sm {
				background-image: url("https://storage.googleapis.com/icosm/hero-how-we-work-sm.jpg");
			}
		}
		.info-box {
			background-position: 20% 50%;
			height: 40vh;
			background-image: url("https://storage.googleapis.com/icosm/cosmetic-inject-desktop.jpg");
			@include md {
				background-position: 60% 50%;
			}
			@include sm {
				height: 60vh;
				background-position: center;
				background-size: cover;
				background-image: url("https://storage.googleapis.com/icosm/cosmetic-inject-sm.jpg");
				h3 {
					font-size: 6.5vw;
				}
			}
		}
	}
	#popup-login-cont {
		@include sm{
			top: 100px!important;
		}
	}
	.user_area{
		a {
			color: $icosmColorTertiary;
		}
	}

	// Join
	.join-form.ui.form {
		label, .ui.checkbox label {
			color: $icosmColorPrimary;
			font-size: 1.375rem!important;
			line-height: 31px;
			font-weight: normal!important;
			@include sm {
				font-size: 1.188rem!important;
			}
		}
		input, .ui.fluid.dropdown {
			max-width: 507px;
		}
		input:focus, .ui.selection.dropdown:focus, .ui.selection.active.dropdown .menu {
			border-color: $icosmColorQuaternary!important;
		}
		.ui.checkbox input ~ label {
			&:before, &:after {
				top: .4rem;
			}
		}
	}

	.ui.form .field > label {
		font-size:1em;
		font-weight:bold;
	}

	.contact {
		.hero {
			background-image:url("https://storage.googleapis.com/icosm/hero-contact.jpg");
			@include sm {
				background-image:url("https://storage.googleapis.com/icosm/hero-contact-sm.jpg");
			}
		}
		.contact-details {
			max-width: 400px;
			h4 { color: $icosmColorLight; }
			p { @include font-size(1.13rem, 1rem); }
		}

		.ui.basic.segment {
			@include hideScroll;
		}
		
		.ui.form {	
			background-color: $icosmColorPrimary;
			padding: 40px;
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			flex-shrink:0;
			align-content: space-between;
			.details .field {
				width: 100%;
				height: 272px;
			}
			.field {
				display: flex;
				flex-direction: column;
				width: calc(50% - 12px);
				@include sm {
					width: 100%;
					margin-right: 0!important;
				}
				
				&:nth-child(odd){
					margin-right: 24px;
				}
				> label {
					color: $icosmColorSecondary;
					width: 100%;
					margin: 0 0 .5em 0;
					font-size: 14px;
					line-height: 23px;
					font-weight: bold;
				}
				.ui.input input, textarea {
					border-radius: 19px!important;
				}
				&.required.field > label:after {
					content: ""
				}
			}
			.ui.input input, textarea {
				width: 100%;
				border: none;
				border-radius: 0.2em;
				flex: 100%;
				border: 1px solid transparent;
				@include hover_transition;
				&:focus {
					border-color: lighten($icosmColor, 20%);
				}
			}
			.button {
				color: $icosmColorLight;
				background-color: $icosmColorDark;
				text-transform: uppercase;
				font-size: 1rem;
				border-radius: 2em;
				font-family: $icosmFont;
				width: 142px;
				margin: 37px auto 0!important;
				&:hover {
					background-color: $icosmColor;
				}
			}
		}
	}

	&.profile {
		h2 { font-size: 1.5em; }
		#user_signed_area .ui.button {
			&.primary {
				@include cta_basic;
			}
			&.basic {
				@include cta_basic;
			}
		}
	}
	.ui.button.green{
		@include cta_tertiary;
	}
	.ui.button.orange{
		@include cta_quaternary;
	}

	.icosm-orgs >.ui.stackable {
		min-height: 50vh;
	}

	.bg-amber-500 {
		background-color: $icosmColor;
	}

	.ui.breadcrumb.shop {
		*, .active {
			color: $icosmColorPrimary;
		}
	}

	.terms, 
	.privacy {
		p, li {
			@include font_main;
			line-height: 22px;
		}
		h1 {
			font-size: 2.2rem;
		}
		h3 {
			font-size: 2rem;
			color: $icosmColorDark;
		}
		h4 {
			font-size: 1.5rem;
		}
	}

	.cosm-button {
		&.tertiary{
			@include cta_tertiary;
			@include nav_btn;
			@apply px-4;
		}
		&.quaternary{
			@include cta_quaternary;
			@include nav_btn;
			@apply px-4;
		}
	}
	.ui.basic.segment.list-table, .list-table table {
		max-width: 100%!important;
		overflow-x: scroll;
	}

	.page-cont {
		@media only screen and (max-width: 1200px) {
			width: 100%!important;
		}
	}

}


body.icosm.list, body.icosm.users {
	.ui.search.selection.dropdown {
		height: auto;
	}
	.ui.fluid.container.wide-content {
		max-width: 100%;
		> div table{
			overflow: scroll;
			max-width: 100%;
		}
	}
}

body.icosm {
	.fc .fc-toolbar-title {
		font-weight:400;
		font-size:1.5em;
	}
	.fc-button-primary {
		background-color: $icosmColorPrimary;
		&:disabled {
			background-color: lighten($icosmColorPrimary,5%);
		}
		&:not(:disabled).fc-button-active {
			background-color: $icosmColorQuaternary;
			border-color: $icosmColorQuaternary;
		}
	}
}