$iprepBlue: #5395E9;
$iprepGreen:#00b8b0;
$iprepPink:#d460ef;
$iprepDarkGreen:#2C6A6A;
$iprepTextColor: #474c55;
$font1: "LemonYellowSun","Helvetica Neue", "Lucida Grande", "Arial", sans-serif;

body.iprep {
	color:$iprepTextColor;
	#main-menu {
		background:$iprepBlue !important;
		.header.item {
			padding:0 !important;
			height: calc(110px - 30px);
			@include sm {
				height:60px;
			}
		}
		.logo {
			position: relative;
			max-width: none;
			height: 110px;
			bottom: -28px;
			@include sm {
				height:80px;
				bottom:-20px;
			}
		}
		.user-menu {
			font-family: $font1;
			.menu { 
				background:$iprepBlue;
				
			}
		}
		&.switch {
			.header.item {
				height:auto;
			}
			.logo {
				bottom:0;
			}
		}
		* {
			color: white;
		}
		.menu-items-wrap.top {
			> .menu-items {
				width:100%;
				justify-content:center;
			};
		}
		.item.main {
			font-family:$font1;
			font-size:1.4em;
			transition: all .2s ease-in;
			padding-left: (0.7619em / 2);
			padding-right: (0.7619em / 2);
			margin-left: (0.7619em / 2);
			margin-right: (0.7619em / 2);
			border-radius:3px;
			height:100%;
			&:hover {
				color:$iprepBlue;
				background-color:white;
			}
			@include sm {
				display:none;
				&.sm {
					display:flex;
				}
			}
			small {
				font-size:.8em;
				align-self: flex-end;
			}
			
			
		}
		
	}
	#site-footer {
		padding:3rem 0 1rem !important;
		background-color: $iprepBlue !important;
		img.is {
			max-height:50px;
		}
		.list {
			text-transform: uppercase;
			.item { 
				color:white !important;
			}
		}
		p {
			margin-bottom: 0.3em;
		}
	}
	.bm-menu {
		.side-logo {
			width:auto;
			height:80px;
		}
	}
	
	// h1 {
	// 	color: $iprepTextColor;
	// }

	
	.ui.block.header {
		background:transparent;
		padding: 0.5rem 0rem;
		box-shadow: none;
		border:none;
		border-bottom: 2px solid $iprepGreen;
		border-radius: 0;
		font-weight: 700;
		color: $iprepGreen;
		margin-bottom: 2rem;
		font-family: $font1;
	}

	h1,h2,h3 {
		font-family:$font1;
		font-weight:normal;
	}

	h3.dec {
		font-family: inherit;
		color:$iprepTextColor;
		font-size:1em;
	}

	.get-started {
		background-color:transparent !important;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='268.21' height='67.05' viewBox='0 0 268.21 67.05'%3E%3Cpath id='Path_316' data-name='Path 316' d='M133.085,560.673a19,19,0,0,1-12.81-9.888c-2.089-4.207-2.626-9.316-2.6-14.25.043-6.83,1.158-13.844,4.269-19.494a27.405,27.405,0,0,1,16.68-12.907c6.542-1.888,13.341-1.746,20.058-1.595l92.493,2.085c10.437.236,20.893.47,31.3-.515,9.808-.928,19.54-2.938,29.361-3.607,18.739-1.275,34.28,1.407,52.818,5.015,4.555.887,9.544,2.07,12.431,6.506,2.346,3.6,5.328,9.523,5.682,14.125.948,12.316-4.869,27.515-13.267,31.956-3.8,2.012-8.04,2.305-12.175,2.559-73.066,4.491-134.486,4.6-207.612,2.016C143.546,562.231,138.178,561.883,133.085,560.673Z' transform='translate(-116.178 -498.688)' fill='%23d460ef' stroke='%23fff' stroke-width='3'/%3E%3C/svg%3E%0A") !important;
		&:hover {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='268.21' height='67.05' viewBox='0 0 268.21 67.05'%3E%3Cpath id='Path_316' data-name='Path 316' d='M133.085,560.673a19,19,0,0,1-12.81-9.888c-2.089-4.207-2.626-9.316-2.6-14.25.043-6.83,1.158-13.844,4.269-19.494a27.405,27.405,0,0,1,16.68-12.907c6.542-1.888,13.341-1.746,20.058-1.595l92.493,2.085c10.437.236,20.893.47,31.3-.515,9.808-.928,19.54-2.938,29.361-3.607,18.739-1.275,34.28,1.407,52.818,5.015,4.555.887,9.544,2.07,12.431,6.506,2.346,3.6,5.328,9.523,5.682,14.125.948,12.316-4.869,27.515-13.267,31.956-3.8,2.012-8.04,2.305-12.175,2.559-73.066,4.491-134.486,4.6-207.612,2.016C143.546,562.231,138.178,561.883,133.085,560.673Z' transform='translate(-116.178 -498.688)' fill='%230096ec' stroke='%23fff' stroke-width='3'/%3E%3C/svg%3E%0A") !important;
		}
		transition:all .1s ease-in;
		color:white !important;
		background-size: contain !important;
		background-repeat: no-repeat !important;
		background-position: center center !important;
		min-width:140px;
		justify-content: center;
		text-align: center;
		&.action {
			padding:.6em .6em;
			font-family: $font1;
			color:white;
			font-size:1.6em;
			display:block;
			margin:0 auto;
			&.big {
				font-size:2.2em;
			}
		}
	}

	section {
		position:relative;
		background-color: #fff;
		padding: 1em 0;
		@include md {
			padding: 3em 0em;
		}

		&.form-area {
			padding:1em 1em;
			@include md {
				padding:0em 0em;
				
			}
		}
		
		span.anc {
			position: absolute;
			top:-3em;
		}
		
		&:last-child {
			padding-bottom: 2em;
			> .container:after {
				content: none;
			}
		}

		h1,h2,h3 {
			color:$iprepGreen;
		}
		
		h1 {
			margin-bottom: 0.2em;
			text-align:center;
			font-size:40px;
			@include md {
				font-size:70px;
			}
			@include xxl {
				font-size:100px;
			}
			> small {
				font-size:.75em;
			}
			> em {
				font-style: normal;
				color:$iprepTextColor;
				display:block;
				margin-top:-0.4em;
				font-size:.7em;
				line-height: 1em;
			}
			&.hero {
				font-size:50px;
				@include md {
					font-size:90px;
				}
				@include xxl {
					font-size:120px;
				}
				>img {
					// display:block;
					margin-top:1em;
					max-width:80%;
					@include md {
						max-width:400px;
					}
				}
				&.in {
					margin-bottom:0.5em !important;
					>img {
						max-width:60%;
						@include md {
							max-width:300px;
						}
					}
				}
			}
		}

		h2 {
			font-size:30px;
			@include md {
				font-size:60px;
			}
			@include xxl {
				font-size:69px;
			}
			font-weight:normal;
			text-align:center;
		}
		h3 {
			margin-top:1em;
			font-size:25px;
			@include md {
				font-size:30px;
			}
			@include xxl {
				font-size:35px;
			}
			font-weight:normal;
		}
		p {
			color:$iprepTextColor
		}

		span.bubble {
			color:white;
			position: relative;
			display:inline-block;
			margin-right:20px;
			margin-left:20px;
			&:before {
				position: absolute;
				top:-20px;
				left:-20px;
				right:-50px;
				bottom: -20px;
				z-index: 0;
				content:"";
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='351.236' height='127.027' viewBox='0 0 351.236 127.027'%3E%3Cpath id='Path_318' data-name='Path 318' d='M473.046,456.516c-4.231-3.651-6.787-8.886-8.488-14.209-6.787-21.237-1.569-45.126-9.574-65.934-1.615-4.2-3.827-8.291-7.257-11.2-2.962-2.509-6.649-3.982-10.325-5.225-39.333-13.308-82.18-4.593-123.682-3.249-43.615,1.411-84.584-5.5-127.724,1.074-7.882,1.2-16.258,3.157-21.607,9.068-6.38,7.049-9.315,29.734-9.873,39.225-1.051,17.927-2.4,25.2,2.87,43.232,2.073,7.1,4.6,14.358,9.746,19.663,8.156,8.411,20.909,10.048,32.616,10.5,55.548,2.125,118.889-11.549,174.283-6.908,24.224,2.031,50.3,7.2,71.59-4.522.412-.227,12.982,6.142,14.828,6.589a49.424,49.424,0,0,0,16.636,1.069c6.182-.642,28.006-5.7,27.693-14.083C493.663,460.123,482.957,465.063,473.046,456.516Z' transform='translate(-153.546 -352.661)' fill='%234fbab1'/%3E%3C/svg%3E%0A");
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
			}
			>span {
				position: relative;
				z-index:1;
			}
		}
		
		> .container {
			display:flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding:1em 0;
			@include md {
				padding:1em 10%;
			}
			> h1:first-child {
				margin-bottom:.5em;
				@include md {
					margin-bottom:1em;
				}
			}
			
		}

		&.primary {
			background:$iprepGreen;
			h1,h2,h3,p,a,li {
				color:white;
			}
			h1 {
				&.alt {
					color:$iprepDarkGreen;
				}
				> em {
					color:white;
				}
			}
			
		
		}
		&.secondary {
			// background:$iprepGreen;
			// padding: 0 0 0;
			&:before {
				position: relative;
				top:-2em;
				@include md {
					top:-4em;
				}
				content:"";
				display:block;
				background:url('https://storage.googleapis.com/instant-med-public/iprep/white-bg.svg');
				height:30px;
				background-position: top center;
				background-size:100% auto;
			}
			&:after {
				position: relative;
				bottom:-2em;
				@include md {
					bottom:-4em;
				}
				content:"";
				display:block;
				background:url('https://storage.googleapis.com/instant-med-public/iprep/white-bg.svg');
				height:30px;
				background-position: bottom center;
				background-size:100% auto;
			}
			&:last-child:after {
				content:none;
			}
			
		}

		
			// @include sm {
			// 	.row > .column {
			// 		&:first-child {
			// 			width: 60% !important;
			// 			font-size: 0.9em !important;

			// 			h1 {
			// 				font-size: 1.8rem;
			// 			}
			// 		}

			// 		&:last-child {
			// 			width: 40% !important;
			// 		}
			// 	}
			// }
			// // min-height:60vh;
			

			// .row > .column {
			// 	&:first-child {
			// 		display: flex;
			// 		flex-direction: column;
			// 		justify-content: center;
			// 		@include md {
			// 			// padding: 3em 0;
			// 		}
			// 		@include sm {
			// 			font-size: 0.9em !important;
			// 		}
			// 	}

			// 	&:last-child {}
			// }
	}

			
	div.prices.ui.grid {
		@media only screen and (min-width: 992px) {
			> .row > .column {
				width:calc(100% / 3) !important;
			}
		}
	}
	div.price {
		margin-bottom:1em;
		> h3 {
			background:white;
			color:$iprepGreen;
			margin:0;
			padding:.5em 0.1em;
			border-radius:10px 10px 0 0;
			font-size:23px;
			text-align: center;
			white-space: nowrap;
		}
		>div {
			background:$iprepDarkGreen;
			padding:1em 1em;
			border-radius:0 0 10px 10px;
			color:white;
		}
		strong {
			font-size:70px;
			line-height:1em;
			display:block;
			font-weight: normal;
			font-family:$font1;
			text-align: center;
			>small {
				display:block;
				font-size:20px;
				line-height:20px;

			}
		}
		p {
			margin:1em 0;
			font-size:16px;
		}
		// <h3>Non-medicare card holders</h3>
		// 						<div>
		// 							<strong>$XXX <small>per X months</small></strong>
		// 							<p>Lorem ipsum dolor sit amet, consect etuer adipiscing elit, sed diam nonummy nibh.</p>
		// 						</div>
	}

	.features {
		margin-top:1em;
		svg {
			height:80px;
			@apply mx-auto mb-4;
		}
		h3 {
			margin-top:0;
		}
		.column {
			text-align: center;
		}
	}

	div.learn {
		margin:2em 0;
		@include sm {
			svg {
				width:calc(330.15px / 1.5);
				height:calc(67.052px / 1.5);
			}
		}
	}
	.routes {
		@include sm {
			.arrow {
				display: none !important;
			}
			.column {
				text-align: center;
			}
			
		}
		&.grid .grid > .row > .column {
			padding-bottom:0 !important;
		}
	}
	
	img.d-icon {
		height:60px;
	}

	.sm {
		@include md {
			display:none;
		}
	}
	.md {
		@include sm {
			display:none;
		}
	}

	.back-btn {
		display:flex;
		width:100%;
		justify-content: flex-start;
		margin:2em 0;
	}
	.pbs {
		@include sm {
			> svg {
				display:block;
				max-width:220px;
				height:auto;
				display:block;
				margin:0 auto;
			}
	
		}
	}

	&.home {
		p {
			@include md {
				font-size:18px;
			}
		}
		#page-wrap > main {
			padding-top:0;
			padding-bottom:0;
		}

		svg { 
			
			transform: rotate(0deg) scale(1);
			> g > path {
				transition: all .2s ease-in;
			}
			&:hover {
				> g > path {
					fill:$iprepPink;
				}
			}
		}

	}
	.segment {
		// background:$iprepGreen;
		// border-color:transparent !important;
		// &,label {
		// 	color:white !important;
		// }
	}
	// label,
	.button {
		font-family:$font1;
		
	}
	.button {
		// font-size:1.2em !important;
		
	}
	.field > label {
		// font-weight:normal !important;
	}
	#page-wrap > main {
		background:white;
		padding-top:0em;
		padding-bottom:0;
		> .container:first-child {
			padding-top:3em;
		}

		> section {
			flex: 1;
		}
	}
	.bm-menu-wrap {
		.item {
			br { 
				display:none; 
			}
		}
	}
	.meds {
		margin-top:2em;
		h2 {
			font-size:1.6em;
			text-align: left;
		}
		label { 
			font-family: inherit;
		}
	}
	.support {
		.button {
			align-items: center;
			display: flex;
			justify-content: center;
		}
	}
}
